import { Card } from "antd";
import styles from "../../styles/exchange/Exchange.module.scss";

import { ReactComponent as GainIcon } from "../../assets/icons/gain-arrow.svg";
import { ReactComponent as LossIcon } from "../../assets/icons/loss-arrow.svg";
import { Link } from "react-router-dom";

const CoinComponent = ({ coin }) => {
  return (
    <>
      <Link to={`/exchange/coins/${coin.coinSymbol}`}>
        <Card style={{ marginBottom: 20 }}>
          <div className={styles.coinContainer}>
            <div className={styles.coinDetails}>
              <img
                className={styles.coinIcon}
                src={coin.coinIcon}
                alt={`${coin.coinName} icon`}
              />
              <div>
                <p className={styles.coinName}>{coin.coinName}</p>
                <p className={styles.coinSymbol}>{coin.coinSymbol}</p>
              </div>
            </div>
            <div>
              <p className={styles.coinPrice}>
                $
                {coin.coinSymbol === "SHIB"
                  ? Number(coin.price).toFixed(4)
                  : Number(coin.price)}
              </p>
              <div>
                {coin.coinSymbol === "NOMOX" ? (
                  <p className={`${styles.presaleMsg}`}>Presale Ongoing</p>
                ) : (
                  <p
                    className={`${styles.priceChange} ${
                      Number(coin.priceChangePercent) >= 0 ? "u-green" : "u-red"
                    }`}
                  >
                    {Number(coin.priceChangePercent) >= 0 ? (
                      <GainIcon />
                    ) : (
                      <LossIcon />
                    )}{" "}
                    {Number(coin.priceChangePercent).toFixed(2)}%{" "}
                    <span className={styles.changePeriod}>24h</span>
                  </p>
                )}
              </div>
            </div>
          </div>
        </Card>
      </Link>
    </>
  );
};

export default CoinComponent;
