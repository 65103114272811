import config from "../utils/config";
import { buyNomoxCoin } from "../validators/nomoxValidator";

export const getUserNomoxHoldingAPI = async (get) => {
  const response = await get(
    `${config.ORDERS_API_URL}/api/v1/orders/nomox/coin-holding`
  );
  return response;
};

export const getNomoxCoinAPI = async (get) => {
  const response = await get(
    `${config.ORDERS_API_URL}/api/v1/orders/nomox/coin`
  );
  return response;
};

export const buyNomoxCoinAPI = async (post, data) => {
  const validate = buyNomoxCoin(data);

  if (validate.success) {
    const response = await post(
      `${config.ORDERS_API_URL}/api/v1/orders/nomox/buy`,
      data
    );

    return response;
  } else {
    return validate;
  }
};
