import { useState } from "react";
import { notification } from "antd";

import styles from "../../styles/auth/auth.module.scss";
import useAPICall from "../../hooks/useAPICall";
import { sendSignupOTPAPI, signupAPI } from "../../apis/profileAPIs";
import SignupForm from "../../components/auth/SignupForm";

const SignupPage = () => {
  const { post } = useAPICall();
  const [api, contextHolder] = notification.useNotification();

  const [loading, setLoading] = useState(false);
  const [signupState, setSignupState] = useState("SENDOTP");
  const [signupData, setSignupData] = useState({
    email: "",
    name: "",
    password: "",
    phone: "",
    country: "",
    countryCode: "",
    otp: "",
  });

  const sendOTPHandler = async () => {
    setLoading(true);
    const data = {
      email: signupData.email,
    };
    const response = await sendSignupOTPAPI(post, data);

    if (response.success) {
      setSignupState("VERIFYOTP");
    } else {
      api["error"]({
        message: "Error",
        description: response.message || "Something went wrong!",
      });
    }
    setLoading(false);
  };

  const verifyOTPHandler = async () => {
    setLoading(true);
    const data = {
      email: signupData.email,
      otp: signupData.otp,
      name: signupData.name,
      password: signupData.password,
      phone: signupData.phone,
      country: signupData.country,
      countryCode: signupData.countryCode,
      notifyToken: "",
    };
    const response = await signupAPI(post, data);

    if (response.success) {
      setSignupData({
        email: "",
        name: "",
        password: "",
        phone: "",
        country: "",
        countryCode: "",
        otp: "",
      });
    } else {
      api["error"]({
        message: "Error",
        description: response.message || "Something went wrong!",
      });
    }
    setLoading(false);
  };

  return (
    <>
      {contextHolder}
      <div className={styles.pageLayout}>
        <div className={styles.container}>
          {/* <div className={styles.imageContainer}>Image</div> */}
          <SignupForm
            loading={loading}
            signupState={signupState}
            signupData={signupData}
            setSignupData={setSignupData}
            sendOTPHandler={sendOTPHandler}
            verifyOTPHandler={verifyOTPHandler}
          />
        </div>
      </div>
    </>
  );
};

export default SignupPage;
