import { Tabs, notification } from "antd";
import OrderTabs from "../../components/transactions/OrderTabs";
import StatementTabs from "../../components/transactions/StatementTabs";
import styles from "../../styles/transactions/Transaction.module.scss";
import "../../styles/common/packageCSS.scss";
import useAPICall from "../../hooks/useAPICall";
import { useEffect, useState } from "react";
import { getAllOrderHistoriesAPI } from "../../apis/orderHistoriesAPIs";
import { getAllTransactionsAPI } from "../../apis/transactionsAPIs";

const TransactionPage = () => {
  const { get } = useAPICall();
  const [api, contextHolder] = notification.useNotification();

  const [openOrders, setOpenOrders] = useState([]);
  const [orderHistories, setOrderHistories] = useState([]);
  const [transactions, setTransactions] = useState([]);

  const [totalOpenOrders, setTotalOpenOrders] = useState(0);
  const [totalOrderHistories, setTotalOrderHistories] = useState(0);
  const [totalTransactions, setTotalTransactions] = useState(0);

  const [queryParamsOpenOrders, setQueryParamsOpenOrders] = useState({
    pagination: {
      page: 1,
      limit: 20,
      orderBy: "_id",
      sort: "desc",
    },
    filters: {
      orderStatus: ["IP"],
    },
  });

  const [queryParamsOrderHistories, setQueryParamsOrderHistories] = useState({
    pagination: {
      page: 1,
      limit: 20,
      orderBy: "_id",
      sort: "desc",
    },
    filters: {
      orderStatus: ["F", "S"],
    },
  });

  const [queryParamsTransactions, setQueryParamsTransactions] = useState({
    pagination: {
      page: 1,
      limit: 20,
      orderBy: "_id",
      sort: "desc",
    },
    filters: {},
  });

  const [loading, setLoading] = useState(false);

  const fetchOpenOrders = async () => {
    setLoading(true);
    const response = await getAllOrderHistoriesAPI(get, queryParamsOpenOrders);

    if (response.success) {
      setOpenOrders(response.data);
      setTotalOpenOrders(response.metaData.total);
    } else {
      api["error"]({
        message: "Error",
        description: response.message || "Something went wrong!",
      });
    }
    setLoading(false);
  };

  const fetchOrderHistories = async () => {
    setLoading(true);
    const response = await getAllOrderHistoriesAPI(
      get,
      queryParamsOrderHistories
    );

    if (response.success) {
      setOrderHistories(response.data);
      setTotalOrderHistories(response.metaData.total);
    } else {
      api["error"]({
        message: "Error",
        description: response.message || "Something went wrong!",
      });
    }
    setLoading(false);
  };

  const fetchTransactions = async () => {
    setLoading(true);
    const response = await getAllTransactionsAPI(get, queryParamsTransactions);

    if (response.success) {
      setTransactions(response.data);
      setTotalTransactions(response.metaData.total);
    } else {
      api["error"]({
        message: "Error",
        description: response.message || "Something went wrong!",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchOpenOrders();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(queryParamsOpenOrders)]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchOrderHistories();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(queryParamsOrderHistories)]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchTransactions();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(queryParamsTransactions)]);

  const updateOpenOrdersQueryHandler = (page) => {
    setQueryParamsOpenOrders((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page: page,
      },
    }));
  };
  const updateOrderHistoriesQueryHandler = (page) => {
    setQueryParamsOrderHistories((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page: page,
      },
    }));
  };
  const updateTransactionsQueryHandler = (page) => {
    setQueryParamsTransactions((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page: page,
      },
    }));
  };

  const items = [
    {
      key: "1",
      label: `Orders`,
      children: (
        <OrderTabs
          openOrders={openOrders}
          totalOpenOrders={totalOpenOrders}
          queryParamsOpenOrders={queryParamsOpenOrders}
          updateOpenOrdersQueryHandler={updateOpenOrdersQueryHandler}
          orderHistories={orderHistories}
          totalOrderHistories={totalOrderHistories}
          queryParamsOrderHistories={queryParamsOrderHistories}
          updateOrderHistoriesQueryHandler={updateOrderHistoriesQueryHandler}
          loading={loading}
        />
      ),
    },
    {
      key: "2",
      label: `Statement`,
      children: (
        <StatementTabs
          transactions={transactions}
          totalTransactions={totalTransactions}
          queryParamsTransactions={queryParamsTransactions}
          updateTransactionsQueryHandler={updateTransactionsQueryHandler}
          loading={loading}
        />
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <div className={styles.pageLayout}>
        <Tabs
          items={items}
          centered
          size="large"
          // onChange={onChange}
        />
      </div>
    </>
  );
};

export default TransactionPage;
