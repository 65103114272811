import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import styles from "../../../styles/portfolio/INRDeposit.module.scss";
import commonStyles from "../../../styles/common/Common.module.scss";
import InProgressIcon from "../../../assets/icons/in-progress-icon.svg";

const SuccessScreen = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.successScreenContainer}>
      <img
        src={InProgressIcon}
        alt="success-icon"
        className={styles.successIcon}
      />
      <div className={styles.successMessages}>
        <p>
          The balance will appear in your account only after your transaction
          get approved by our team.
        </p>
        <p>It will take up to 12 hrs to get approved.</p>
      </div>
      <Button
        className={commonStyles.primaryButton}
        onClick={() => navigate("/portfolio")}
      >
        Done
      </Button>
    </div>
  );
};

export default SuccessScreen;
