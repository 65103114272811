import { TbCopy } from "react-icons/tb";
import QRCode from "react-qr-code";
import styles from "../../styles/portfolio/Deposit.module.scss";
import InputSelect from "../common/InputSelect";
import { message } from "antd";

const DepositCoin = ({
  coinDetails,
  selectedChain,
  setSelectedChain,
  depositAddress,
  loading,
}) => {
  return (
    <>
      <p className={styles.depositMessage}>
        Send {coinDetails.coinName} to the given address
      </p>
      <div className={styles.coinDetailsContainer}>
        <div className={styles.coinDetails}>
          <p className={styles.detailTitle}>Coin</p>
          <div className={styles.coinDetail}>
            <img
              src={coinDetails.coinIcon}
              alt={`${coinDetails.coinName} logo`}
              className={styles.coinIcon}
            />
            <p className={styles.coinName}>{coinDetails.coinName}</p>
          </div>
        </div>
        <div className={styles.chainDetails}>
          <p className={styles.detailTitle}>Network</p>
          <InputSelect
            allowClear={false}
            showSearch={false}
            value={selectedChain}
            onChange={(value) => setSelectedChain(value)}
            options={
              coinDetails.chains?.map((el) => ({
                value: el.name,
                label: el.name,
              })) || []
            }
          />
        </div>
      </div>
      <div className={styles.qrCodeContainer}>
        {!loading && <QRCode value={depositAddress} size={180} />}
      </div>
      <div className={styles.addressDetailsContainer}>
        <p className={styles.detailHeading}>Destination Address</p>
        {!loading && (
          <div className={styles.depositAddressContainer}>
            <p className={styles.depositAddress}>{depositAddress}</p>
            <TbCopy
              className={styles.copyIcon}
              onClick={async () => {
                await navigator.clipboard.writeText(depositAddress);
                message.success("Deposit address copied to clipboard");
              }}
            />
          </div>
        )}
        <div className={styles.warningContainer}>
          <p className={styles.warningMessage}>
            <span className={`u-red`}>Warning Note:</span> Minimum deposit is{" "}
            {
              coinDetails.chains?.find((el) => el.name === selectedChain)
                ?.minDep
            }{" "}
            {coinDetails.coinSymbol} & please copy correct address, we are not
            responsible for any loss.
          </p>
        </div>
      </div>
    </>
  );
};

export default DepositCoin;
