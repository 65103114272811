import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { notification } from "antd";

import styles from "../../styles/portfolio/Deposit.module.scss";
import DepositCoin from "../../components/portfolio/DepositCoin";
import useAPICall from "../../hooks/useAPICall";
import { getUserDepositAddressAPI } from "../../apis/portfolioAPIs";
import { getAllChainsAPI } from "../../apis/coinsAPIs";
import PageHeading from "../../components/common/PageHeading";

const DepositCoinPage = () => {
  const params = useParams();
  const { get } = useAPICall();
  const [api, contextHolder] = notification.useNotification();

  const coinSymbol = params.coinSymbol;
  const [coinDetails, setCoinDetails] = useState([]);
  const [selectedChain, setSelectedChain] = useState("");
  const [depositAddress, setDepositAddress] = useState("");
  const [loading, setLoading] = useState(false);

  const getAllCoinsWithChains = async () => {
    setLoading(true);
    const response = await getAllChainsAPI(get, {});

    if (response.success) {
      const coin = response.data.find((el) => el.coinSymbol === coinSymbol);

      if (coin) {
        setCoinDetails(coin);
        setSelectedChain(coin.chains[0]?.name);
      }
    } else {
      api["error"]({
        message: "Error",
        description: response.message || "Something went wrong!",
      });
    }
    setLoading(false);
  };

  const fetchDepositAddresses = async () => {
    setLoading(true);
    const response = await getUserDepositAddressAPI(get, {
      filters: {
        coinSymbol: [coinSymbol],
        chain: [selectedChain],
      },
    });

    if (response.success) {
      setDepositAddress(response.data);
    } else {
      api["error"]({
        message: "Error",
        description: response.message || "Something went wrong!",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getAllCoinsWithChains();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await fetchDepositAddresses();
    };

    if (selectedChain) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChain]);

  return (
    <>
      {contextHolder}
      <div className={styles.pageLayout}>
        <PageHeading heading={"Deposit"} />
        <DepositCoin
          coinDetails={coinDetails}
          selectedChain={selectedChain}
          setSelectedChain={setSelectedChain}
          depositAddress={depositAddress}
          loading={loading}
        />
      </div>
    </>
  );
};

export default DepositCoinPage;
