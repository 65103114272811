import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { notification } from "antd";

import NomoexWallet from "../../components/portfolio/NomoexWallet";
import UserInvestments from "../../components/portfolio/UserInvestments";
import useAPICall from "../../hooks/useAPICall";

import styles from "../../styles/portfolio/Portfolio.module.scss";
import {
  getUserPortfolioAPI,
  updatePortfolioAPI,
} from "../../apis/portfolioAPIs";

const PortfolioPage = () => {
  const { get, post } = useAPICall();
  const [api, contextHolder] = notification.useNotification();

  const reduxCoinData = useSelector((state) => state.coin.coinData);

  const [userPortfolio, setUserPortfoilo] = useState({});
  const [coinsData, setCoinsData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchUserPortfolio = async () => {
    setLoading(true);
    const response = await getUserPortfolioAPI(get);

    if (response.success) {
      setUserPortfoilo(response.data);
    } else {
      api["error"]({
        message: "Error",
        description: response.message || "Something went wrong!",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchUserPortfolio();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCoinsData(reduxCoinData.allCoins || []);
  }, [reduxCoinData.allCoins]);

  const updatePortfolioHandler = async () => {
    const response = await updatePortfolioAPI(post, {});

    if (response.success) {
      await fetchUserPortfolio();
    } else {
      api["error"]({
        message: "Error",
        description: response.message || "Something went wrong!",
      });
    }
  };

  return (
    <>
      {contextHolder}
      <div className={styles.pageLayout}>
        <h2 className="umb-1">My Portfolio</h2>
        <NomoexWallet
          userPortfolio={userPortfolio}
          coinsData={coinsData}
          loading={loading}
          updatePortfolioHandler={updatePortfolioHandler}
        />
        <h2 className="umb-1">My Investments</h2>
        <UserInvestments userPortfolio={userPortfolio} coinsData={coinsData} />
      </div>
    </>
  );
};

export default PortfolioPage;
