import config from "../utils/config";
import { createQueryString } from "../utils/helper";
import {
  buyCoin,
  sellCoin,
  withdrawToken,
  cancelOrder,
} from "../validators/portfolioValidator";

export const getUserDepositAddressAPI = async (get, queryParams) => {
  const queryString = createQueryString(queryParams);
  const response = await get(
    `${config.ORDERS_API_URL}/api/v1/orders/user/deposit-address${queryString}`
  );
  return response;
};

export const buyCoinAPI = async (post, data) => {
  const validate = buyCoin(data);

  if (validate.success) {
    const response = await post(
      `${config.ORDERS_API_URL}/api/v1/orders/portfolio/buy`,
      data
    );

    return response;
  } else {
    return validate;
  }
};

export const sellCoinAPI = async (post, data) => {
  const validate = sellCoin(data);

  if (validate.success) {
    const response = await post(
      `${config.ORDERS_API_URL}/api/v1/orders/portfolio/sell`,
      data
    );

    return response;
  } else {
    return validate;
  }
};

export const updatePortfolioAPI = async (post, data) => {
  const response = await post(
    `${config.ORDERS_API_URL}/api/v1/orders/portfolio/update`,
    data
  );

  return response;
};

export const sendWithdrawOTPAPI = async (get) => {
  const response = await get(
    `${config.ORDERS_API_URL}/api/v1/orders/portfolio/withdraw/otp`
  );
  return response;
};

export const withdrawCoinAPI = async (post, data) => {
  const validate = withdrawToken(data);

  if (validate.success) {
    const response = await post(
      `${config.ORDERS_API_URL}/api/v1/orders/portfolio/withdraw`,
      data
    );

    return response;
  } else {
    return validate;
  }
};

export const cancelCoinOrderAPI = async (post, data) => {
  const validate = cancelOrder(data);

  if (validate.success) {
    const response = await post(
      `${config.ORDERS_API_URL}/api/v1/orders/portfolio/cancel`,
      data
    );

    return response;
  } else {
    return validate;
  }
};

export const getUserCoinHoldingsAPI = async (get, coinId) => {
  const response = await get(
    `${config.ORDERS_API_URL}/api/v1/orders/portfolio/coin-holding?coinId=${coinId}`
  );
  return response;
};

export const getUserUSDTInfoAPI = async (get) => {
  const response = await get(
    `${config.ORDERS_API_URL}/api/v1/orders/portfolio/usdt-coin`
  );
  return response;
};

export const getUserPortfolioAPI = async (get) => {
  const response = await get(
    `${config.ORDERS_API_URL}/api/v2/orders/portfolio`
  );
  return response;
};
