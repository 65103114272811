import config from "../utils/config";
import {
  sendLoginOTP,
  verifyOTP,
  sendSignupOTP,
  signUp,
  sendOTPforPassword,
  verifyOTPforPassword,
  changePassword,
} from "../validators/profileValidator";

export const sendLoginOTPAPI = async (post, data) => {
  const validate = sendLoginOTP(data);

  if (validate.success) {
    const response = await post(
      `${config.AUTH_API_URL}/api/v1/auth/login`,
      data
    );

    return response;
  } else {
    return validate;
  }
};

export const loginAPI = async (post, data) => {
  const validate = verifyOTP(data);

  if (validate.success) {
    const response = await post(
      `${config.AUTH_API_URL}/api/v1/auth/verify`,
      data
    );

    return response;
  } else {
    return validate;
  }
};

export const logoutAPI = async (get) => {
  const response = await get(`${config.AUTH_API_URL}/api/v1/auth/logout`);
  return response;
};

export const sendSignupOTPAPI = async (post, data) => {
  const validate = sendSignupOTP(data);

  if (validate.success) {
    const response = await post(
      `${config.AUTH_API_URL}/api/v1/auth/signup/email/otp`,
      data
    );

    return response;
  } else {
    return validate;
  }
};

export const signupAPI = async (post, data) => {
  const validate = signUp(data);

  if (validate.success) {
    const response = await post(
      `${config.AUTH_API_URL}/api/v1/auth/signup/email/verify`,
      data
    );

    return response;
  } else {
    return validate;
  }
};

export const sendChangePasswordOTPAPI = async (post, data) => {
  const validate = sendOTPforPassword(data);

  if (validate.success) {
    const response = await post(
      `${config.AUTH_API_URL}/api/v1/auth/password/otp`,
      data
    );

    return response;
  } else {
    return validate;
  }
};

export const verifyChangePasswordOTPAPI = async (post, data) => {
  const validate = verifyOTPforPassword(data);

  if (validate.success) {
    const response = await post(
      `${config.AUTH_API_URL}/api/v1/auth/password/verify`,
      data
    );

    return response;
  } else {
    return validate;
  }
};

export const changePasswordAPI = async (post, data) => {
  const validate = changePassword(data);

  if (validate.success) {
    const response = await post(
      `${config.AUTH_API_URL}/api/v1/auth/password/change`,
      data
    );

    return response;
  } else {
    return validate;
  }
};

export const getProfileAPI = async (get) => {
  const response = await get(`${config.AUTH_API_URL}/api/v1/auth/profile`);
  return response;
};
