import { useEffect, useState } from "react";
import { notification } from "antd";

import styles from "../../styles/portfolio/Withdraw.module.scss";
import WithdrawCoin from "../../components/portfolio/WithdrawCoin";
import { getUserPortfolioAPI } from "../../apis/portfolioAPIs";
import useAPICall from "../../hooks/useAPICall";
import { getAllCoinAPI } from "../../apis/coinsAPIs";
import PageHeading from "../../components/common/PageHeading";

const WithdrawPage = () => {
  const { get } = useAPICall();
  const [api, contextHolder] = notification.useNotification();

  const [coinHoldings, setCoinHoldings] = useState([]);
  const [coinsData, setCoinsData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchCoinHoldings = async () => {
    setLoading(true);
    const response = await getUserPortfolioAPI(get);

    if (response.success) {
      setCoinHoldings(response.data.portfolio.coins);
    } else {
      api["error"]({
        message: "Error",
        description: response.message || "Something went wrong!",
      });
    }
    setLoading(false);
  };

  const getAllCoins = async () => {
    setLoading(true);
    const response = await getAllCoinAPI(get, {});

    if (response.success) {
      setCoinsData(response.data);
    } else {
      api["error"]({
        message: "Error",
        description: response.message || "Something went wrong!",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getAllCoins();
      await fetchCoinHoldings();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {contextHolder}
      <div className={styles.pageLayout}>
        <PageHeading heading={"Withdraw"} />
        <WithdrawCoin
          coinsData={coinsData}
          coinHoldings={coinHoldings}
          loading={loading}
        />
      </div>
    </>
  );
};
export default WithdrawPage;
