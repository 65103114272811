import { Select } from "antd";
import styles from "../../styles/common/Common.module.scss";

const InputSelect = ({
  placeholder,
  value,
  onChange,
  className,
  options,
  allowClear = true,
  showSearch = true,
  style,
}) => {
  const inputSelectStyle = className
    ? [styles.inputSelect, className].join(" ")
    : styles.inputSelect;

  return (
    <Select
      showSearch={showSearch}
      allowClear={allowClear}
      size="large"
      placeholder={placeholder || null}
      value={value || null}
      onChange={onChange}
      className={inputSelectStyle}
      style={style || {}}
      options={options || []}
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
    />
  );
};

export default InputSelect;
