import { Button } from "antd";
import InputText from "../common/InputText";
import InputSelect from "../common/InputSelect";
import NumberInput from "../common/NumberInput";

import styles from "../../styles/portfolio/Withdraw.module.scss";
import commonStyles from "../../styles/common/Common.module.scss";

const WithdrawCoinForm = ({
  coinDetails,
  selectedChain,
  setSelectedChain,
  userHoldings,
  loading,
  withdrawData,
  setWithdrawData,
  withdrawState,
  sendWithdrawOTPHandler,
  withdrawHandler,
}) => {
  const chain = coinDetails.chains?.find((el) => el.name === selectedChain);

  return (
    <>
      <p className={styles.withdrawHeading}>Send {coinDetails.coinSymbol}</p>
      <div>
        <div className={styles.formPropertyContainer}>
          <p className={styles.propertyTitle}>Address</p>
          <InputText
            placeholder={"Paste here"}
            value={withdrawData.address}
            onChange={(e) =>
              setWithdrawData((prevState) => ({
                ...prevState,
                address: e.target.value,
              }))
            }
          />
        </div>
        <div className={styles.formPropertyContainer}>
          <p className={styles.propertyTitle}>Network</p>
          <InputSelect
            allowClear={false}
            showSearch={false}
            value={selectedChain}
            onChange={(value) => setSelectedChain(value)}
            options={
              coinDetails.chains?.map((el) => ({
                value: el.name,
                label: el.name,
              })) || []
            }
          />
          <div className={styles.propertyDetails}>
            <p className={styles.propertyDetailTitle}>Network Fee</p>
            <p className={styles.propertyDetailValue}>
              {(chain?.wFee || 0)?.toFixed(4)} {coinDetails.coinSymbol}
            </p>
          </div>
        </div>
        <div className={styles.formPropertyContainer}>
          <p className={styles.propertyTitle}>{`Amount (Min: ${(
            (chain?.minWid || 0) + (chain?.wFee || 0)
          )?.toFixed(4)} ${coinDetails.coinSymbol})`}</p>
          <NumberInput
            placeholder={"0.0"}
            value={withdrawData.quantity}
            onChange={(value) =>
              setWithdrawData((prevState) => ({
                ...prevState,
                quantity: +value,
              }))
            }
            controls={false}
          />
          <div className={styles.propertyDetails}>
            <p className={styles.propertyDetailTitle}>Available</p>
            <p className={styles.propertyDetailValue}>
              {userHoldings?.toFixed(4)} {coinDetails.coinSymbol}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.withdrawDetails}>
        <p className={styles.withdrawDetailTitle}>{`Receivable Amount:`}</p>
        <p className={styles.withdrawDetailMessage}>
          {((withdrawData.quantity || 0) - (chain?.wFee || 0))?.toFixed(4)}{" "}
          {coinDetails.coinSymbol}
        </p>
      </div>
      <div className={styles.warningContainer}>
        <p className={styles.warningMessage}>
          <span className={`u-red`}>Warning Note:</span> Please paste correct
          address, we are not responsible for any loss.
        </p>
      </div>
      {withdrawState === "WITHDRAW" && (
        <div className={styles.formPropertyContainer}>
          <p className={styles.propertyTitle}>OTP</p>
          <NumberInput
            placeholder={"Enter OTP sent to your registered email"}
            value={withdrawData.otp}
            onChange={(value) =>
              setWithdrawData((prevState) => ({ ...prevState, otp: +value }))
            }
            controls={false}
          />
        </div>
      )}
      <Button
        loading={loading}
        className={`${commonStyles.primaryButton} umb-3`}
        onClick={() =>
          withdrawState !== "WITHDRAW"
            ? sendWithdrawOTPHandler()
            : withdrawHandler()
        }
      >
        {withdrawState !== "WITHDRAW" ? "Continue" : "Withdraw"}
      </Button>
    </>
  );
};

export default WithdrawCoinForm;
