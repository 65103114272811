import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import CoinsList from "../../components/exchange/CoinsList";
import ExchangeSearch from "../../components/exchange/ExchangeSearch";

import useAPICall from "../../hooks/useAPICall";
import { getAllCategoriesAPI } from "../../apis/categoresAPIs";

import styles from "../../styles/exchange/Exchange.module.scss";

const ExchangePage = () => {
  const { get } = useAPICall();
  const reduxCoinData = useSelector((state) => state.coin.coinData);

  const [categories, setCategories] = useState([]);
  const [showErrorScreen, setShowErrorScreen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const fetchCategories = async () => {
    const response = await getAllCategoriesAPI(get);

    if (response.success) {
      setCategories(response.data);
    } else {
      setShowErrorScreen(true);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchCategories();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   // function eventHandler(value) {
  //   //   setCoinsData(value);
  //   // }

  //   categories.forEach((cat) => {
  //     socket.on(cat.catPath, (value) =>
  //       setCoinsData((prevState) => ({ ...prevState, [cat.catPath]: value }))
  //     );
  //   });

  //   // socket.on("connect", onConnect);
  //   // socket.on("disconnect", onDisconnect);
  //   // socket.on(catPath, eventHandler);

  //   return () => {
  //     // socket.off("connect", onConnect);
  //     // socket.off("disconnect", onDisconnect);
  //     // socket.off(catPath, eventHandler);
  //     categories.forEach((cat) => {
  //       socket.off(cat.catPath, (value) =>
  //         setCoinsData((prevState) => ({ ...prevState, [cat.catPath]: value }))
  //       );
  //     });
  //   };
  // }, [categories]);

  return (
    <>
      <div className={styles.pageLayout}>
        {!showErrorScreen ? (
          <>
            <ExchangeSearch
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
            <CoinsList
              categories={categories}
              coinsData={reduxCoinData}
              searchValue={searchValue}
            />
          </>
        ) : (
          <p>Something went wrong</p>
        )}
      </div>
    </>
  );
};

export default ExchangePage;
