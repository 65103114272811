import React, { useEffect } from "react";
import styles from "../../styles/auth/auth.module.scss";

const Timer = ({ delay, setDelay }) => {
  const seconds = Math.floor(delay % 60);
  useEffect(() => {
    const timer = setInterval(() => {
      setDelay(delay - 1);
    }, 1000);

    if (delay === 0) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  });

  return (
    <>
      <span className={styles.forgetPasswordHighlightedTitle}>{seconds}s</span>
    </>
  );
};

export default Timer;
