import config from "../utils/config";
import { createQueryString } from "../utils/helper";

export const getAllCategoriesAPI = async (get, queryParams = {}) => {
  const queryString = createQueryString(queryParams);
  const response = await get(
    `${config.COINPRICE_API_URL}/api/v1/coinprice/categories/unauth${queryString}`
  );
  return response;
};
