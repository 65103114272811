import { Route, Routes, Navigate } from "react-router-dom";
import PortfolioPage from "../pages/portfolio/PortfolioPage";
import DepositPage from "../pages/portfolio/DepositPage";
import DepositCoinPage from "../pages/portfolio/DepositCoinPage";
import WithdrawPage from "../pages/portfolio/WithdrawPage";
import WithdrawCoinPage from "../pages/portfolio/WithdrawCoinPage";
import INRDepositPage from "../pages/portfolio/INRDepositPage";

const PortfolioRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<PortfolioPage />} />
      <Route path="/deposit" element={<DepositPage />} />
      <Route path="/deposit/crypto/:coinSymbol" element={<DepositCoinPage />} />
      <Route path="/deposit/fiat/INR" element={<INRDepositPage />} />
      <Route path="/withdraw/" element={<WithdrawPage />} />
      <Route path="/withdraw/:coinSymbol" element={<WithdrawCoinPage />} />
      <Route path="*" element={<Navigate replace to="" />} />
    </Routes>
  );
};

export default PortfolioRoutes;
