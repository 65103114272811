import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Header from "./components/layout/Header";
import IndexRoute from "./routes/IndexRoutes";

import useAPICall from "./hooks/useAPICall";
import { socket } from "./socket/socket";
import { getAllCategoriesAPI } from "./apis/categoresAPIs";
import { setCoinDataAction } from "./redux/actions/coinAction";
import { getWhatsAppSupportLinkAPI } from "./apis/commonServicesAPIs";

function App() {
  const [width, setWidth] = useState(window.innerWidth);
  const { pathname } = useLocation();
  const { get } = useAPICall();
  const dispatch = useDispatch();

  const [categories, setCategories] = useState([]);
  const [whatsAppSupportLink, setWhatsAppSupportLink] = useState("");

  useEffect(() => {
    const updateDimension = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const fetchCategories = async () => {
    const response = await getAllCategoriesAPI(get);

    if (response.success) {
      setCategories(response.data);
    }
  };

  const fetchWhatsAppSupportLink = async () => {
    const response = await getWhatsAppSupportLinkAPI(get);

    if (response.success) {
      setWhatsAppSupportLink(response.data);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchCategories();
      await fetchWhatsAppSupportLink();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    categories.forEach((cat) => {
      socket.on(cat.catPath, (value) =>
        dispatch(setCoinDataAction({ [cat.catPath]: value }))
      );
    });

    return () => {
      categories.forEach((cat) => {
        socket.off(cat.catPath, (value) =>
          dispatch(setCoinDataAction({ [cat.catPath]: value }))
        );
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  return (
    <>
      {width >= 280 ? (
        <>
          <Header whatsAppSupportLink={whatsAppSupportLink} />
          <IndexRoute />
        </>
      ) : (
        <p>The app does not support for current screen size</p>
      )}
    </>
  );
}

export default App;
