import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { notification } from "antd";

import styles from "../../styles/portfolio/Withdraw.module.scss";
import WithdrawCoinForm from "../../components/portfolio/WithdrawCoinForm";
import useAPICall from "../../hooks/useAPICall";
import { getAllChainsAPI } from "../../apis/coinsAPIs";
import {
  getUserCoinHoldingsAPI,
  sendWithdrawOTPAPI,
  withdrawCoinAPI,
} from "../../apis/portfolioAPIs";
import PageHeading from "../../components/common/PageHeading";

const WithdrawCoinPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { get, post } = useAPICall();
  const [api, contextHolder] = notification.useNotification();

  const coinSymbol = params.coinSymbol;
  const [coinDetails, setCoinDetails] = useState([]);
  const [selectedChain, setSelectedChain] = useState("");
  const [userHoldings, setUserHolding] = useState(0);
  const [loading, setLoading] = useState(false);
  const [withdrawState, setWithdrawState] = useState("SENDOTP");

  const [withdrawData, setWithdrawData] = useState({
    coinId: "",
    address: "",
    chain: "",
    quantity: "",
    otp: "",
  });

  const getAllCoinsWithChains = async () => {
    setLoading(true);
    const response = await getAllChainsAPI(get, {});

    if (response.success) {
      const coin = response.data.find((el) => el.coinSymbol === coinSymbol);

      if (coin) {
        setCoinDetails(coin);
        setSelectedChain(coin.chains[0]?.name);
      }
    } else {
      api["error"]({
        message: "Error",
        description: response.message || "Something went wrong!",
      });
    }
    setLoading(false);
  };

  const fetchUserHoldings = async () => {
    const response = await getUserCoinHoldingsAPI(get, coinDetails._id);

    if (response.success) {
      setUserHolding(response.data);
    } else {
      api["error"]({
        message: "Error",
        description: response.message || "Something went wrong!",
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getAllCoinsWithChains();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await fetchUserHoldings();
    };

    if (coinDetails._id) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coinDetails]);

  const sendWithdrawOTPHandler = async () => {
    setLoading(true);
    const response = await sendWithdrawOTPAPI(get);

    if (response.success) {
      setWithdrawState("WITHDRAW");
      api["success"]({
        message: "Success",
        description: "OTP sent to your registered email",
      });
    } else {
      api["error"]({
        message: "Error",
        description: response.message || "Something went wrong!",
      });
    }
    setLoading(false);
  };

  const withdrawHandler = async () => {
    setLoading(true);
    const data = {
      coinId: coinDetails._id,
      address: withdrawData.address,
      chain: selectedChain,
      quantity: withdrawData.quantity,
      otp: withdrawData.otp,
    };

    const response = await withdrawCoinAPI(post, data);

    if (response.success) {
      api["success"]({
        message: "Success",
        description: response.message,
      });
      navigate("/portfolio/withdraw");
    } else {
      api["error"]({
        message: "Error",
        description: response.message || "Something went wrong!",
      });
    }
    setLoading(false);
  };

  return (
    <>
      {contextHolder}
      <div className={styles.pageLayout}>
        <PageHeading heading={"Withdraw"} />
        <WithdrawCoinForm
          coinDetails={coinDetails}
          selectedChain={selectedChain}
          setSelectedChain={setSelectedChain}
          userHoldings={userHoldings}
          loading={loading}
          withdrawData={withdrawData}
          setWithdrawData={setWithdrawData}
          withdrawState={withdrawState}
          sendWithdrawOTPHandler={sendWithdrawOTPHandler}
          withdrawHandler={withdrawHandler}
        />
      </div>
    </>
  );
};

export default WithdrawCoinPage;
