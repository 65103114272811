import { Button } from "antd";
import styles from "../../styles/profile/SocialMediIcons.module.scss";
import instagram from "../../assets/icons/instagram.svg";
import twitter from "../../assets/icons/twitter.svg";
import telegram from "../../assets/icons/telegram.svg";

const SocialMediaIcons = () => {
  return (
    <div className={styles.socialMedia_cont}>
      <a target="_blank" href={"https://t.me/nomoexin/"} rel="noreferrer">
        <Button shape="circle" className={styles.socailMediabtn}>
          <img src={telegram} alt="telegram" />
        </Button>
      </a>
      <a
        target="_blank"
        href={"https://instagram.com/nomoex.in/"}
        rel="noreferrer"
      >
        <Button shape="circle" className={styles.socailMediabtn}>
          <img src={instagram} alt="instagram" />
        </Button>
      </a>
      <a
        target="_blank"
        href={"https://twitter.com/nomoex_in/"}
        rel="noreferrer"
      >
        <Button shape="circle" className={styles.socailMediabtn}>
          <img src={twitter} alt="twitter" />
        </Button>
      </a>
    </div>
  );
};

export default SocialMediaIcons;
