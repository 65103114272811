import { MdOutlineArrowBack } from "react-icons/md";
import styles from "../../styles/common/Common.module.scss";
import { useNavigate } from "react-router-dom";

const PageHeading = ({ heading }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.headingContainer}>
      <MdOutlineArrowBack
        className={styles.backIcon}
        onClick={() => navigate(-1)}
      />
      <h3 className={styles.pageHeading}>{heading}</h3>
      <div className={styles.emptySpace} />
    </div>
  );
};

export default PageHeading;
