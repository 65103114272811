const initialState = {
  coinData: {},
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_COIN_DATA":
      return {
        ...state,
        coinData: { ...state.coinData, ...action.payload },
      };
    default:
      return state;
  }
};

export default authReducer;
