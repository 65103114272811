import { Link } from "react-router-dom";
import { Button } from "antd";
import moment from "moment";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

import styles from "../../styles/transactions/Transaction.module.scss";

const OrderDetails = ({
  orders,
  totalOrders,
  queryParams,
  updateOrdersQueryHandler,
}) => {
  const previousHandler = () => {
    const page =
      queryParams.pagination.page === 1 ? 1 : queryParams.pagination.page - 1;

    updateOrdersQueryHandler(page);
  };

  const nextHandler = () => {
    const page =
      totalOrders < queryParams.pagination.page * queryParams.pagination.limit
        ? queryParams.pagination.page
        : queryParams.pagination.page + 1;

    updateOrdersQueryHandler(page);
  };

  return (
    <>
      {orders.map((el, i) => (
        <Link key={i} to={`/transactions/order/${el.orderId}`}>
          <div className={styles.orderCard}>
            <div className={styles.orderHeader}>
              <img
                className={styles.coinIcon}
                src={el.coinDetail?.coinIcon}
                alt={`${el.coinDetail?.coinSymbol} logo`}
              />
              <div className={styles.orderHeaderDetails}>
                <div className={styles.orderStatusContainer}>
                  {el.orderStatus === "S" ? (
                    <p className={`u-green ${styles.successOrderStatus}`}>
                      {el.orderType === "buy" || el.orderType === "nomox"
                        ? "Buy executed"
                        : el.orderType === "sell"
                        ? "Sell executed"
                        : ""}
                    </p>
                  ) : el.orderStatus === "F" ? (
                    <p className={`u-red ${styles.failedOrderStatus}`}>
                      {el.orderType === "buy" || el.orderType === "nomox"
                        ? "Buy failed"
                        : el.orderType === "sell"
                        ? "Sell failed"
                        : ""}
                    </p>
                  ) : (
                    el.orderStatus === "IP" &&
                    (el.orderType === "buy" || el.orderType === "nomox" ? (
                      <p className={`u-green ${styles.successOrderStatus}`}>
                        Buy
                      </p>
                    ) : el.orderType === "sell" ? (
                      <p className={`u-red ${styles.failedOrderStatus}`}>
                        Sell
                      </p>
                    ) : (
                      ""
                    ))
                  )}
                  <p className={styles.coinName}>{el.coinDetail.coinName}</p>
                </div>
                <p className={styles.orderDate}>
                  {moment(el.orderDate).format("DD MMMM YYYY, hh:mm a")}
                </p>
              </div>
            </div>
            <div className={styles.orderDetailsContainer}>
              <p className={styles.detailTitle}>Asset Value</p>
              <p className={styles.detailValue}>
                {el.holdings} {el.coinDetail.coinSymbol}
              </p>
            </div>
            <div className={styles.orderDetailsContainer}>
              <p className={styles.detailTitle}>Order Price</p>
              <p className={styles.detailValue}>${el.orderPrice}</p>
            </div>
            <hr />
            <div className={styles.orderDetailsContainer}>
              <p className={styles.detailTitle}>Total Amount</p>
              <p className={styles.detailValue}>${el.totalAmount}</p>
            </div>
          </div>
        </Link>
      ))}
      <div className={styles.paginationContainer}>
        {queryParams.pagination.page > 1 && (
          <Button className={styles.previousButton} onClick={previousHandler}>
            <BsArrowLeft /> Previous
          </Button>
        )}
        {totalOrders >
          queryParams.pagination.page * queryParams.pagination.limit && (
          <Button className={styles.nextButton} onClick={nextHandler}>
            Next <BsArrowRight />
          </Button>
        )}
      </div>
    </>
  );
};

export default OrderDetails;
