import { useState, useEffect } from "react";
import styles from "../../styles/portfolio/Portfolio.module.scss";
import { Link } from "react-router-dom";

const UserInvestments = ({ userPortfolio, coinsData }) => {
  const [coinsList, setCoinsList] = useState([]);

  useEffect(() => {
    const coins =
      userPortfolio.portfolio?.coins
        .filter((el) => el.coinDetail?.coinSymbol !== "USDT")
        .map((el) => ({
          coinIcon: el.coinDetail?.coinIcon,
          coinName: el.coinDetail?.coinName,
          coinSymbol: el.coinDetail?.coinSymbol,
          holdings: el.holdings,
          holdingValue:
            (el.holdings || 0) *
            coinsData.find((ele) => ele.id === el.coinId)?.price,
        })) || [];

    setCoinsList([
      {
        coinIcon: userPortfolio.portfolio?.usdtInfo.coinDetail?.coinIcon,
        coinName: userPortfolio.portfolio?.usdtInfo.coinDetail?.coinName,
        coinSymbol: userPortfolio.portfolio?.usdtInfo.coinDetail?.coinSymbol,
        holdings: userPortfolio.portfolio?.usdtInfo.holdings,
        holdingValue: (userPortfolio.portfolio?.usdtInfo.holdings || 0) * 1,
      },
      {
        coinIcon: userPortfolio.nomox?.coinDetail?.coinIcon,
        coinName: userPortfolio.nomox?.coinDetail?.coinName,
        coinSymbol: userPortfolio.nomox?.coinDetail?.coinSymbol,
        holdings: userPortfolio.nomox?.holdings,
        holdingValue: (userPortfolio.nomox?.holdings || 0) * 0.05,
      },
      ...coins,
    ]);
  }, [userPortfolio, coinsData]);

  return (
    <>
      <div className={styles.investmentContainer}>
        <p className={styles.coinsHeading}>Coins</p>
        {coinsList.map((el, i) => (
          <Link
            key={i}
            to={
              el.coinSymbol === "USDT" ? "" : `/exchange/coins/${el.coinSymbol}`
            }
          >
            <div className={styles.coinContainer}>
              <div className={styles.coinDetails}>
                <img
                  className={styles.coinIcon}
                  src={el.coinIcon}
                  alt={`${el.coinName} logo`}
                />
                <div>
                  <p className={styles.coinName}>{el.coinName}</p>
                  <p className={styles.coinHoldings}>
                    {el.holdings?.toFixed(6)} {el.coinSymbol}
                  </p>
                </div>
              </div>
              <div className={styles.coinPriceContainer}>
                <p className={styles.coinPrice}>
                  ${el.holdingValue?.toFixed(4)}
                </p>
              </div>
            </div>
          </Link>
        ))}
        {/* <div className={styles.coinContainer}>
          <div className={styles.coinDetails}>
            <img
              className={styles.coinIcon}
              src="https://cdn-icons-png.flaticon.com/512/5968/5968260.png"
              alt="coin logo"
            />
            <div>
              <p className={styles.coinName}>Bitcoin</p>
              <p className={styles.coinHoldings}>0.00000 USDT</p>
            </div>
          </div>
          <div className={styles.coinPriceContainer}>
            <p className={styles.coinPrice}>$0.00000</p>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default UserInvestments;
