import { useEffect, useState } from "react";
import { Button, notification } from "antd";
import { FiLogOut } from "react-icons/fi";
import AboutNomoex from "../../components/profile/AboutNomoex";
import UserProfile from "../../components/profile/UserProfile";
import SocialMediaIcons from "../../components/profile/SocialMediaIcons";
import styles from "../../styles/profile/Profile.module.scss";
import useAPICall from "../../hooks/useAPICall";
import { getProfileAPI, logoutAPI } from "../../apis/profileAPIs";

const ProfilePage = () => {
  const { get } = useAPICall();
  const [api, contextHolder] = notification.useNotification();

  const [profileData, setProfileData] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchProfileData = async () => {
    setLoading(true);
    const response = await getProfileAPI(get);

    if (response.success) {
      setProfileData(response.data);
    } else {
      api["error"]({
        message: "Error",
        description: response.message || "Something went wrong!",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchProfileData();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logoutHandler = async () => {
    const response = await logoutAPI(get);

    if (response.success) {
      api["success"]({
        message: "Success",
        description: response.message,
      });
    } else {
      api["error"]({
        message: "Error",
        description: response.message || "Something went wrong!",
      });
    }
  };

  return (
    <>
      {contextHolder}
      <div className={styles.layout}>
        <div className={styles.container}>
          <UserProfile profileData={profileData} loading={loading} />
          <AboutNomoex />
        </div>
        <div className={styles.socialNetwork_cont}>
          <p className={styles.socialNetwork_heading}>Join us at</p>
          <SocialMediaIcons />
        </div>
        <div className={styles.logoutbtn_cont}>
          <Button
            size="large"
            type="link"
            className={styles.logout_btn}
            onClick={logoutHandler}
          >
            <FiLogOut />
            Sign Out
          </Button>
        </div>
      </div>
    </>
  );
};

export default ProfilePage;
