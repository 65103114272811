import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/authReducer";
import coinReducer from "./reducers/coinReducer";

const reducer = {
  auth: authReducer,
  coin: coinReducer,
};

const store = configureStore({
  reducer,
});

export default store;
