import { io } from "socket.io-client";

const url = "https://socket.nomoex.com";
// const url = "http://socket-services-673528852.ap-south-1.elb.amazonaws.com";
// const url = "http://localhost:8000";

const options = {
  "force new connection": true,
  reconnectionAttempt: "Infinity",
  timeout: 10000,
  transports: ["websocket"],
};

export const socket = io(url, options);
