import config from "../utils/config";
import { createQueryString } from "../utils/helper";
import { watchlistCoin } from "../validators/coinValidator";

export const getAllChainsAPI = async (get, queryParams) => {
  const queryString = createQueryString(queryParams);
  const response = await get(
    `${config.COINPRICE_API_URL}/api/v1/coinprice/coins/chains${queryString}`
  );
  return response;
};

export const getAllCoinAPI = async (get, queryParams) => {
  const queryString = createQueryString(queryParams);
  const response = await get(
    `${config.COINPRICE_API_URL}/api/v1/coinprice/coins${queryString}`
  );
  return response;
};

export const getOneCoinAPI = async (get, coinId) => {
  const response = await get(
    `${config.COINPRICE_API_URL}/api/v1/coinprice/coins/${coinId}`
  );
  return response;
};

export const getOneCoinBySymbolAPI = async (get, coinSymbol) => {
  const response = await get(
    `${config.COINPRICE_API_URL}/api/v1/coinprice/coins/symbol/${coinSymbol}`
  );
  return response;
};

export const getCoinHistoriesAPI = async (get, queryParams) => {
  const queryString = createQueryString(queryParams);
  const response = await get(
    `${config.COINPRICE_API_URL}/api/v1/coinprice/histories${queryString}`
  );
  return response;
};

export const getUserWatchlistAPI = async (get, queryParams) => {
  const queryString = createQueryString(queryParams);
  const response = await get(
    `${config.COINPRICE_API_URL}/api/v1/coinprice/watchlist${queryString}`
  );
  return response;
};

export const addCoinToWatchlistAPI = async (patch, data) => {
  const validate = watchlistCoin(data);

  if (validate.success) {
    const response = await patch(
      `${config.COINPRICE_API_URL}/api/v1/coinprice/watchlist/add`,
      data
    );

    return response;
  } else {
    return validate;
  }
};

export const removeCoinToWatchlistAPI = async (patch, data) => {
  const validate = watchlistCoin(data);

  if (validate.success) {
    const response = await patch(
      `${config.COINPRICE_API_URL}/api/v1/coinprice/watchlist/remove`,
      data
    );

    return response;
  } else {
    return validate;
  }
};
