import { Route, Routes, Navigate } from "react-router-dom";
import ProfilePage from "../pages/profile/ProfilePage";

const ProfileRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ProfilePage/>} />
      <Route path="*" element={<Navigate replace to="" />} />
    </Routes>
  );
};

export default ProfileRoutes;
