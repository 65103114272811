import { useState, useEffect } from "react";
import { Tabs, notification } from "antd";
import TabFiat from "../../components/portfolio/TabFiat";
import TabCrypto from "../../components/portfolio/TabCrypto";
import styles from "../../styles/portfolio/DepositTabs.module.scss";
import useAPICall from "../../hooks/useAPICall";
import { getUserDepositAddressAPI } from "../../apis/portfolioAPIs";
import { getAllChainsAPI } from "../../apis/coinsAPIs";
import PageHeading from "../../components/common/PageHeading";

const DepositPage = () => {
  const { get } = useAPICall();
  const [api, contextHolder] = notification.useNotification();

  const [usdtTRC20DepositAddress, setUSDTTRC20DepositAddress] = useState("");
  const [coinsWithChain, setCoinsWithChain] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchUSDTTRC20DepositAddresses = async () => {
    setLoading(true);
    const response = await getUserDepositAddressAPI(get, {
      filters: {
        coinSymbol: ["USDT"],
        chain: ["USDT-TRC20"],
      },
    });

    if (response.success) {
      setUSDTTRC20DepositAddress(response.data);
    } else {
      api["error"]({
        message: "Error",
        description: response.message || "Something went wrong!",
      });
    }
    setLoading(false);
  };

  const getAllCoinsWithChains = async () => {
    setLoading(true);
    const response = await getAllChainsAPI(get, {});

    if (response.success) {
      setCoinsWithChain(response.data);
    } else {
      api["error"]({
        message: "Error",
        description: response.message || "Something went wrong!",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchUSDTTRC20DepositAddresses();
      await getAllCoinsWithChains();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const items = [
    {
      key: "1",
      label: `Fiat`,
      children: (
        <TabFiat
          usdtTRC20DepositAddress={usdtTRC20DepositAddress}
          loading={loading}
        />
      ),
    },
    {
      key: "2",
      label: `Crypto`,
      children: <TabCrypto coinsWithChain={coinsWithChain} loading={loading} />,
    },
  ];

  return (
    <>
      {contextHolder}
      <div className={styles.container}>
        <PageHeading heading={"Deposit"} />
        <Tabs centered items={items} />
      </div>
    </>
  );
};
export default DepositPage;
