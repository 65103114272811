export const buyNomoxCoin = (data) => {
  if (
    !data.amount ||
    Number.isNaN(Number(data.amount)) ||
    Number(data.amount) < 1
  ) {
    return {
      success: false,
      message: "Invalid USDT amount.",
    };
  }
  return { success: true };
};
