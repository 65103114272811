import config from "../utils/config";
import { createQueryString } from "../utils/helper";

export const getAllOrderHistoriesAPI = async (get, queryParams) => {
  const queryString = createQueryString(queryParams);
  const response = await get(
    `${config.ORDERS_API_URL}/api/v1/orders/order-history${queryString}`
  );
  return response;
};

export const getOneOrderHistoryAPI = async (get, orderId) => {
  const response = await get(
    `${config.ORDERS_API_URL}/api/v1/orders/order-history/${orderId}`
  );
  return response;
};
