import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, notification } from "antd";
import { IoIosArrowBack } from "react-icons/io";

import CoinDetails from "../../components/exchange/CoinDetails";
// import CoinGraph from "../../components/exchange/CoinGraph";
import UserHoldings from "../../components/exchange/UserHoldings";
import CoinInformation from "../../components/exchange/CoinInformation";
import CoinActions from "../../components/exchange/CoinActions";
// import FlipCountdownTimer from "../../components/exchange/FlipCountdownTimer";
import Spinner from "../../components/common/Spinner";

import styles from "../../styles/exchange/CoinPage.module.scss";

import useAPICall from "../../hooks/useAPICall";
import { getOneCoinBySymbolAPI } from "../../apis/coinsAPIs";
import {
  buyNomoxCoinAPI,
  getNomoxCoinAPI,
  getUserNomoxHoldingAPI,
} from "../../apis/NomoxAPIs";
import {
  buyCoinAPI,
  getUserCoinHoldingsAPI,
  sellCoinAPI,
} from "../../apis/portfolioAPIs";
import BuyActions from "../../components/exchange/BuyActions";
import SellActions from "../../components/exchange/SellActions";

const CoinPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { get, post } = useAPICall();
  const [api, contextHolder] = notification.useNotification();

  const reduxCoinData = useSelector((state) => state.coin.coinData);

  const coinSymbol = params.coinSymbol;
  const [coinData, setCoinData] = useState({});
  const [coinPrice, setCoinPrice] = useState({});
  const [userHoldings, setUserHolding] = useState(0);
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [openBuyDrawer, setOpenBuyDrawer] = useState(false);
  const [openSellDrawer, setOpenSellDrawer] = useState(false);

  const [buyData, setBuyData] = useState({
    amount: 0,
    limit: false,
    limitPeriod: "1d",
    limitAmount: 0,
  });

  const [sellData, setSellData] = useState({
    quantity: 0,
    limit: false,
    limitPeriod: "1d",
    amount: 0,
  });

  const fetchCoinDetails = async () => {
    setPageLoading(true);
    if (coinSymbol) {
      const response =
        coinSymbol === "NOMOX"
          ? await getNomoxCoinAPI(get)
          : await getOneCoinBySymbolAPI(get, coinSymbol);

      if (response.success) {
        setCoinData(response.data);
      } else {
        api["error"]({
          message: "Error",
          description: response.message || "Something went wrong!",
        });
      }
    } else {
      api["error"]({
        message: "Error",
        description: "Something went wrong!",
      });
    }
    setPageLoading(false);
  };

  const fetchUserHoldings = async () => {
    const response =
      coinSymbol === "NOMOX"
        ? await getUserNomoxHoldingAPI(get)
        : await getUserCoinHoldingsAPI(get, coinData._id);

    if (response.success) {
      setUserHolding(response.data);
    } else {
      api["error"]({
        message: "Error",
        description: response.message || "Something went wrong!",
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchCoinDetails();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (coinSymbol !== "NOMOX") {
      const coin = reduxCoinData.allCoins?.find(
        (el) => el.coinSymbol === coinSymbol
      );
      setCoinPrice({
        price: coin?.price,
        priceChangePercent: coin?.priceChangePercent,
      });
    }
  }, [coinSymbol, reduxCoinData.allCoins]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchUserHoldings();
    };

    if (coinData._id) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coinData]);

  const buyButtonHandler = () => {
    setOpenBuyDrawer(true);
  };

  const buyCoinHandler = async () => {
    setLoading(true);
    const data = {
      coinId: coinData._id,
      amount: buyData.amount,
      limit: buyData.limit,
    };

    if (buyData.limit) {
      data.limitPeriod = buyData.limitPeriod;
      data.limitAmount = buyData.limitAmount;
    }

    const response =
      coinSymbol === "NOMOX"
        ? await buyNomoxCoinAPI(post, data)
        : await buyCoinAPI(post, data);

    if (response.success) {
      await fetchUserHoldings();
      api["success"]({
        message: "Success",
        description: response.message,
      });
      setOpenBuyDrawer(false);
    } else {
      api["error"]({
        message: "Error",
        description: response.message || "Something went wrong!",
      });
    }
    setLoading(false);
  };

  const sellButtonHandler = () => {
    setOpenSellDrawer(true);
  };

  const sellCoinHandler = async () => {
    setLoading(true);
    const data = {
      coinId: coinData._id,
      quantity: sellData.quantity,
      limit: sellData.limit,
    };

    if (sellData.limit) {
      data.limitPeriod = sellData.limitPeriod;
      data.amount = sellData.amount;
    }

    const response = await sellCoinAPI(post, data);

    if (response.success) {
      await fetchUserHoldings();
      api["success"]({
        message: "Success",
        description: response.message,
      });
      setOpenSellDrawer(false);
    } else {
      api["error"]({
        message: "Error",
        description: response.message || "Something went wrong!",
      });
    }
    setLoading(false);
  };

  return (
    <>
      {contextHolder}
      <>
        <BuyActions
          coinSymbol={coinSymbol}
          open={openBuyDrawer}
          onClose={(anchor, val) => setOpenBuyDrawer(val)}
          buyData={buyData}
          setBuyData={setBuyData}
          loading={loading}
          buyCoinHandler={buyCoinHandler}
        />
        <SellActions
          coinSymbol={coinSymbol}
          open={openSellDrawer}
          onClose={(anchor, val) => setOpenSellDrawer(val)}
          sellData={sellData}
          setSellData={setSellData}
          loading={loading}
          sellCoinHandler={sellCoinHandler}
        />
        {pageLoading ? (
          <Spinner />
        ) : (
          <>
            <div className={styles.pageLayout}>
              <Button
                className={styles.backButton}
                onClick={() => navigate(-1)}
              >
                <IoIosArrowBack />
                Back
              </Button>
              <CoinDetails coinData={coinData} coinPrice={coinPrice} />
              {/* {coinSymbol !== "NOMOX" ? (
                <CoinGraph />
              ) : (
                coinData.disEndDate && (
                  <FlipCountdownTimer endAt={coinData.disEndDate} />
                )
              )} */}
              <UserHoldings
                coinData={coinData}
                coinPrice={coinPrice}
                userHoldings={userHoldings}
              />
              <CoinInformation coinData={coinData} coinPrice={coinPrice} />
            </div>
            <CoinActions
              coinData={coinData}
              userHoldings={userHoldings}
              buyButtonHandler={buyButtonHandler}
              sellButtonHandler={sellButtonHandler}
              loading={loading}
            />
          </>
        )}
      </>
    </>
  );
};

export default CoinPage;
