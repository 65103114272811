import { useNavigate } from "react-router-dom";
import InputPassword from "../common/InputPassword";
import InputText from "../common/InputText";
import { Button } from "antd";

import styles from "../../styles/auth/auth.module.scss";
import commonStyles from "../../styles/common/Common.module.scss";
import NumberInput from "../common/NumberInput";
// import PageHeading from "../common/PageHeading";
import Timer from "../common/Timer";
// import otpReceivedGif from "../../assets/gif/otpVerification.gif";

const ChangePasswordForm = ({
  loading,
  changePasswordState,
  changePasswordData,
  setChangePasswordData,
  sendPasswordChangeOTPHandler,
  verifyPasswordChangeOTPHandler,
  changeNewPasswordHandler,
  delay,
  setDelay,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className={
        changePasswordState === "VERIFYOTP"
          ? styles.pageLayout
          : styles.pageLayout
      }
    >
      <div className={styles.container}>
        <div className={styles.changePasswordFormHolder}>
          <div>
            {changePasswordState === "SENDOTP" && (
              <>
                <div className={styles.formInputContainer}>
                  <div className="umb-1">
                    <h2 className={styles.forgetPasswordHighlightedTitle}>
                      Forgot your password?
                    </h2>
                    <h2 className={styles.forgetPasswordHighlightedTitle}>
                      Don't worry
                    </h2>
                  </div>
                  <div className="umb-1">
                    <p>
                      Enter your Email we will send you a link to reset your
                      password
                    </p>
                  </div>
                  <p className={styles.formInputTitle}>Email</p>
                  <InputText
                    className={styles.formInputBox}
                    value={changePasswordData.email}
                    onChange={(e) =>
                      setChangePasswordData((prevState) => ({
                        ...prevState,
                        email: e.target.value,
                      }))
                    }
                    onPressEnter={
                      loading ? () => {} : sendPasswordChangeOTPHandler
                    }
                  />
                </div>
              </>
            )}
            {changePasswordState === "VERIFYOTP" && (
              <>
                {/* <PageHeading heading={"Verification"} /> */}
                {/* <div>
                  <img
                    src={otpReceivedGif}
                    alt="OTP receiving"
                    className={styles.otpReceivedGif}
                  />
                </div> */}
                <div className={styles.formInputContainer}>
                  <p className={styles.formInputTitle}>
                    Enter OTP sent on {changePasswordData.email}
                  </p>
                  <NumberInput
                    className={styles.formInputBox}
                    controls={false}
                    value={changePasswordData.otp}
                    onChange={(value) =>
                      setChangePasswordData((prevState) => ({
                        ...prevState,
                        otp: +value,
                      }))
                    }
                  />
                </div>

                {delay === 0 ? (
                  <p className={styles.message}>
                    Didn't recieve?{" "}
                    <span
                      className={styles.requestAgain}
                      onClick={
                        loading ? () => {} : sendPasswordChangeOTPHandler
                      }
                    >
                      Request Again
                    </span>
                    .
                  </p>
                ) : (
                  <p className={styles.message}>
                    Send OTP in <Timer delay={delay} setDelay={setDelay} />
                  </p>
                )}
              </>
            )}
            {changePasswordState === "SETPASSWORD" && (
              <>
                <div className={styles.formInputContainer}>
                  <div className="umb-1">
                    <h2 className={styles.forgetPasswordHighlightedTitle}>
                      Enter new Password!
                    </h2>
                  </div>
                  <div className={styles.forgetPasswordInputSubcontainer}>
                    <InputPassword
                      className={styles.formInputBox}
                      placeholder={"Enter Your Password"}
                      value={changePasswordData.newPassword}
                      onChange={(e) =>
                        setChangePasswordData((prevState) => ({
                          ...prevState,
                          newPassword: e.target.value,
                        }))
                      }
                      onPressEnter={
                        loading ? () => {} : sendPasswordChangeOTPHandler
                      }
                    />
                    <InputPassword
                      className={styles.formInputBox}
                      placeholder={"Re-enter Your Password"}
                      value={changePasswordData.confirmNewPassword}
                      onChange={(e) =>
                        setChangePasswordData((prevState) => ({
                          ...prevState,
                          confirmNewPassword: e.target.value,
                        }))
                      }
                      onPressEnter={
                        loading ? () => {} : changeNewPasswordHandler
                      }
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <div>
            {changePasswordState === "SENDOTP" && (
              <>
                <div className={commonStyles.actionButtons}>
                  <Button
                    loading={loading}
                    className={commonStyles.primaryButton}
                    onClick={sendPasswordChangeOTPHandler}
                    disabled={!changePasswordData.email}
                  >
                    Send
                  </Button>
                </div>
                <hr />
                <div className={commonStyles.actionButtons}>
                  <Button
                    loading={loading}
                    className={commonStyles.secondaryButton}
                    onClick={() => navigate(-1)}
                  >
                    Back to login
                  </Button>
                </div>
              </>
            )}
            {changePasswordState === "VERIFYOTP" && (
              <>
                <div className={commonStyles.actionButtons}>
                  <Button
                    loading={loading}
                    className={commonStyles.primaryButton}
                    onClick={verifyPasswordChangeOTPHandler}
                  >
                    Verify OTP
                  </Button>
                </div>
              </>
            )}
            {changePasswordState === "SETPASSWORD" && (
              <>
                <div className={commonStyles.actionButtons}>
                  <Button
                    loading={loading}
                    className={commonStyles.primaryButton}
                    onClick={changeNewPasswordHandler}
                  >
                    Confirm
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordForm;
