import { Card } from "antd";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { MdOutlineArrowForward } from "react-icons/md";

import { ReactComponent as CurrenciesIcon } from "../../assets/icons/currencies.svg";
import RuppeeIcon from "../../assets/icons/ruppee-icon.svg";
import UPILogo from "../../assets/icons/upi-logo.svg";
import VisaIcon from "../../assets/icons/visa.svg";
import MasterCardIcon from "../../assets/icons/mastercard.svg";

import styles from "../../styles/portfolio/DepositTabs.module.scss";
import "../../styles/common/packageCSS.scss";
import { Link } from "react-router-dom";

// const upiOnrampURL =
//   "https://onramp.money/main/buy/?appId=355489&coinCode=usdt&network=trc20&paymentMethod=1&walletAddress=";
const upiOnrampURL =
  "https://buy.onramper.com/?apiKey=pk_prod_01GRV8ZWSRS3MP5XDEB18A5STE&onlyCryptos=USDT_TRON&wallets=USDT_TRON:";

const TabFiat = ({ loading, usdtTRC20DepositAddress }) => {
  return (
    <div className={styles.fiatContainer}>
      <div className={styles.fiatMessageContainer}>
        <IoMdInformationCircleOutline className={styles.messaegeIcon} />
        <p className={styles.fiatMessage}>
          Hurray! Now you can Buy USDT with Fiat &#128176; &#128184;{" "}
        </p>
      </div>

      <Link to={"/portfolio/deposit/fiat/INR"}>
        <Card hoverable style={{ marginBottom: 20 }}>
          <div className={styles.fiatCard}>
            {/* <RuppeeIcon className={styles.currenciesIcon} /> */}
            <img
              src={RuppeeIcon}
              alt="ruppee-icon"
              className={styles.currenciesIcon}
            />
            <div className={styles.fiatInfoContainer}>
              <div>
                <p className={styles.fiatTitle}>Rupees</p>
                <p className={styles.fiatCurrency}>INR</p>
              </div>
              <div className={styles.paymentMethodContainer}>
                <img
                  src={UPILogo}
                  alt="upi-logo"
                  className={styles.paymentMethodLogo}
                />
              </div>
            </div>
            <div className={styles.fiatActionContainer}>
              <MdOutlineArrowForward className={styles.actionIcon} />
            </div>
          </div>
        </Card>
      </Link>

      <Card
        hoverable
        onClick={() =>
          !loading &&
          window.open(
            upiOnrampURL + usdtTRC20DepositAddress,
            "",
            "directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no"
          )
        }
        style={{ marginBottom: 20 }}
      >
        <div className={styles.fiatCard}>
          <CurrenciesIcon className={styles.currenciesIcon} />
          <div className={styles.fiatInfoContainer}>
            <div>
              <p className={styles.fiatTitle}>85+ Major Currencies</p>
              <p className={styles.fiatCurrency}>INR, Euro, USD, AED, etc.</p>
            </div>
            <div className={styles.paymentMethodContainer}>
              <img
                src={UPILogo}
                alt="upi-logo"
                className={styles.paymentMethodLogo}
              />
              <img
                src={VisaIcon}
                alt="visa-logo"
                className={styles.paymentMethodLogo}
              />
              <img
                src={MasterCardIcon}
                alt="mastercard-logo"
                className={styles.paymentMethodLogo}
              />
            </div>
          </div>
          <div className={styles.fiatActionContainer}>
            <MdOutlineArrowForward className={styles.actionIcon} />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default TabFiat;
