import { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { MdOutlineArrowForward } from "react-icons/md";

import InputText from "../common/InputText";
import styles from "../../styles/portfolio/DepositTabs.module.scss";
import { Link } from "react-router-dom";

const TabCrypto = ({ coinsWithChain, loading }) => {
  const [searchValue, setSearchValue] = useState("");
  const [coinsList, setCoinsList] = useState(coinsWithChain || []);

  useEffect(() => {
    setCoinsList(
      coinsWithChain.filter(
        (coin) =>
          coin.coinSymbol.toLowerCase().includes(searchValue.toLowerCase()) ||
          coin.coinName.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  }, [coinsWithChain, searchValue]);

  return (
    <div className={styles.cryptoContainer}>
      <InputText
        placeholder="Search for your coin"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        prefix={<AiOutlineSearch />}
        className="umb-2"
      />
      <p className={styles.coinHeading}>Select your coin</p>
      {coinsList.map((el, i) => (
        <Link
          key={i}
          to={loading ? "" : `/portfolio/deposit/crypto/${el.coinSymbol}`}
        >
          <div className={styles.coinContainer}>
            <div className={styles.coinDetailContainer}>
              <img
                src={el.coinIcon}
                alt={`${el.coinName} logo`}
                className={styles.coinIcon}
              />
              <div>
                <p className={styles.coinName}>{el.coinName}</p>
                <p className={styles.coinSymbol}>{el.coinSymbol}</p>
              </div>
            </div>
            <div className={styles.actionIconContainer}>
              <MdOutlineArrowForward className={styles.actionIcon} />
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default TabCrypto;
