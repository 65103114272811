import { useState } from "react";
import InputText from "../common/InputText";
import { useEffect } from "react";

import styles from "../../styles/portfolio/Withdraw.module.scss";
import { AiOutlineSearch } from "react-icons/ai";
import { Link } from "react-router-dom";

const WithdrawCoin = ({ coinsData, coinHoldings, loading }) => {
  const [searchValue, setSearchValue] = useState("");
  const [coinsList, setCoinsList] = useState(coinsData || []);

  useEffect(() => {
    setCoinsList(
      coinsData.filter(
        (coin) =>
          coin.coinSymbol.toLowerCase().includes(searchValue.toLowerCase()) ||
          coin.coinName.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  }, [coinsData, searchValue]);

  return (
    <>
      <InputText
        placeholder="Search for your coin"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        prefix={<AiOutlineSearch />}
        className="umb-2"
      />
      <p className={styles.coinHeading}>Your holding</p>
      {coinsList.map((el, i) => (
        <Link
          key={i}
          to={loading ? "" : `/portfolio/withdraw/${el.coinSymbol}`}
        >
          <div className={styles.coinContainer}>
            <div className={styles.coinDetailContainer}>
              <img
                src={el.coinIcon}
                alt={`${el.coinName} logo`}
                className={styles.coinIcon}
              />
              <div>
                <p className={styles.coinName}>{el.coinName}</p>
              </div>
            </div>
            <div className={styles.holdingContainer}>
              <p className={styles.holdings}>
                {(
                  coinHoldings.find((ele) => ele.coinId === el._id)?.holdings ||
                  0
                )?.toFixed(4)}{" "}
                {el.coinSymbol}
              </p>
            </div>
          </div>
        </Link>
      ))}
    </>
  );
};

export default WithdrawCoin;
