import styles from "../../styles/exchange/CoinPage.module.scss";
import { convertToInternationalCurrencySystem } from "../../utils/helper";

const CoinInformation = ({ coinData, coinPrice }) => {
  return (
    <>
      <div className={styles.coinInfoContainer}>
        <h3 className={styles.aboutCoin_heading}>Information</h3>
        {coinData.coinSymbol === "NOMOX" ? (
          <>
            <div className={styles.coinProperties}>
              <div className={styles.coinProperty}>
                <p className={styles.coinPropertyTitle}>Circulating Supply</p>
                <p className={styles.coinPropertyValue}>900M NOMOX</p>
              </div>
              <div className={styles.coinProperty}>
                <p className={styles.coinPropertyTitle}>Market Cap</p>
                <p className={styles.coinPropertyValue}>$45M</p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.coinProperties}>
              <div className={styles.coinProperty}>
                <p className={styles.coinPropertyTitle}>Market Rank</p>
                <p className={styles.coinPropertyValue}>
                  {coinData.marketDetails?.marketRank}
                </p>
              </div>
              <div className={styles.coinProperty}>
                <p className={styles.coinPropertyTitle}>Market Cap</p>
                <p className={styles.coinPropertyValue}>
                  $
                  {convertToInternationalCurrencySystem(
                    (coinData.marketDetails?.currentCirculatingSupply || 0) *
                      Number(coinPrice.price)
                  )}
                </p>
              </div>
            </div>
            <div className={styles.coinProperties}>
              <div className={styles.coinProperty}>
                <p className={styles.coinPropertyTitle}>Circulating Supply</p>
                <p className={styles.coinPropertyValue}>
                  {convertToInternationalCurrencySystem(
                    coinData.marketDetails?.currentCirculatingSupply || 0
                  )}
                </p>
              </div>
              <div className={styles.coinProperty}>
                <p className={styles.coinPropertyTitle}>Total Circulation</p>
                <p className={styles.coinPropertyValue}>
                  {convertToInternationalCurrencySystem(
                    coinData.marketDetails?.totalCirculatingSupply || 0
                  )}
                </p>
              </div>
            </div>
          </>
        )}
      </div>
      <div className={styles.coinInfoContainer}>
        <h3 className={styles.aboutCoin_heading}>
          What is {coinData.coinSymbol}
        </h3>
        <p className={styles.coin_desc}>{coinData.coinDescription}</p>
      </div>
    </>
  );
};

export default CoinInformation;
