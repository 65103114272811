import { message } from "antd";
import moment from "moment";
import { TbCopy } from "react-icons/tb";
import styles from "../../styles/transactions/OneOrderTxn.module.scss";
import PageHeading from "../common/PageHeading";

const TransactionComponent = ({ transaction, txnId }) => {
  return (
    <>
      <PageHeading
        heading={
          transaction.txnType === "deposit"
            ? `Deposit preview`
            : transaction.txnType === "withdraw"
            ? `Withdraw preview`
            : transaction.txnType === "vInvest"
            ? `Investment preview`
            : transaction.txnType === "vRedeem"
            ? `Redeem preview`
            : ""
        }
      />
      <div className={styles.orderDetailsContainer}>
        <div className={styles.orderHeader}>
          <div className={styles.coinDetails}>
            <img
              src={
                transaction.txnType === "vInvest" ||
                transaction.txnType === "vRedeem"
                  ? "https://webapp-nomoex.s3.ap-south-1.amazonaws.com/vaultx/vaultx-icon.png"
                  : transaction.coinDetail?.coinIcon || ""
              }
              alt={`${transaction.coinDetail?.coinSymbol} logo`}
              className={styles.coinIcon}
            />
            <p className={styles.coinSymbol}>
              {transaction.txnType === "vInvest" ||
              transaction.txnType === "vRedeem"
                ? "VaultX"
                : `${transaction.coinDetail?.coinName || ""} /
                  ${transaction.coinDetail?.coinSymbol || ""}`}
            </p>
          </div>
          {transaction.txnStatus === "S" ? (
            <p className={`${styles.successOrderStatus} u-green`}>Completed</p>
          ) : transaction.txnStatus === "F" ? (
            <p className={`${styles.failedOrderStatus} u-red`}>Failed</p>
          ) : transaction.txnStatus === "IP" ? (
            <p className={`${styles.processingOrderStatus}`}>Processing</p>
          ) : transaction.txnStatus === "IN" ? (
            <p className={`${styles.processingOrderStatus}`}>Initiated</p>
          ) : (
            ""
          )}
        </div>
        <div className={styles.orderProperty}>
          <p className={styles.orderPropertyTitle}>Amount</p>
          <p className={styles.orderPropertyValue}>
            {transaction.txnAmount} {transaction.coinDetail?.coinSymbol || ""}
          </p>
        </div>
        <div className={styles.orderProperty}>
          <p className={styles.orderPropertyTitle}>Network</p>
          <p className={styles.orderPropertyValue}>
            {transaction.chain || "-"}
          </p>
        </div>
        <div className={styles.orderProperty}>
          <p className={styles.orderPropertyTitle}>Date & Time</p>
          <p className={styles.orderPropertyValue}>
            {moment(transaction.txnDate).format("DD MMMM YYYY, hh:mm a")}
          </p>
        </div>
        <div className={styles.orderProperty}>
          <p className={styles.orderPropertyTitle}>Address</p>
          <div className={styles.orderPropertyValueContainer}>
            <p className={styles.orderPropertyValue}>
              {transaction.depositAddress || "-"}
            </p>
            {transaction.depositAddress && (
              <TbCopy
                className={styles.copyIcon}
                onClick={async () => {
                  await navigator.clipboard.writeText(
                    transaction.depositAddress
                  );
                  message.success("Transaction address copied to clipboard");
                }}
              />
            )}
          </div>
        </div>
        <div className={styles.orderProperty}>
          <p className={styles.orderPropertyTitle}>Transaction ID</p>
          <div className={styles.orderPropertyValueContainer}>
            <p className={styles.orderPropertyValue}>{txnId}</p>
            <TbCopy
              className={styles.copyIcon}
              onClick={async () => {
                await navigator.clipboard.writeText(txnId);
                message.success("Transaction id copied to clipboard");
              }}
            />
          </div>
        </div>
        <hr />
      </div>
      <div className={styles.helpLinkContainer}>
        <a
          target="_blank"
          href={"mailto:support@nomoex.com"}
          rel="noreferrer"
          className={styles.linkText}
        >
          Need Help? Get Support
        </a>
      </div>
    </>
  );
};

export default TransactionComponent;
