import { Button } from "antd";
import { useEffect } from "react";
import commonStyles from "../../../styles/common/Common.module.scss";
import styles from "../../../styles/portfolio/INRDeposit.module.scss";
import InputText from "../../common/InputText";

const EnterINR = ({
  usdtPrice,
  inrAmount,
  setINRAmount,
  loading,
  initiateUSDTDepositTxn,
}) => {
  const min = 500;
  const max = 50000;

  useEffect(() => {
    setINRAmount(min);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.inrDepositContainer}>
      <h3 className={styles.enterAmountHeading}>Enter INR Amount</h3>
      <div className={styles.inputContainer}>
        <Button
          disabled={loading}
          className={commonStyles.primaryButton}
          onClick={() => setINRAmount(min)}
        >
          Min
        </Button>
        <InputText
          placeholder={"Enter INR Amount"}
          value={inrAmount}
          onChange={(event) =>
            setINRAmount(+event.target.value > max ? max : +event.target.value)
          }
          disabled={loading}
          controls={false}
          className={styles.amountInput}
        />
        <Button
          disabled={loading}
          className={commonStyles.primaryButton}
          onClick={() => setINRAmount(max)}
        >
          Max
        </Button>
      </div>
      <div className={styles.usdtPriceContainer}>
        <p>Current 1 USDT Price ₹{usdtPrice}</p>
      </div>
      <div className={styles.allotedUSDTContainer}>
        <p className={styles.allotedUSDTNote}>
          The given below USDT amount is approximate. Nomoex do not charge any
          fee or service cost.
        </p>
        <div className={styles.allotedUSDT}>
          <p className={styles.allotedUSDTTitle}>You will get</p>
          <p className={styles.allotedUSDTAmount}>
            ≈${((inrAmount || 0) / usdtPrice).toFixed(2)}
          </p>
        </div>
      </div>
      <div className={styles.paymentButtonContainer}>
        <Button
          loading={loading}
          className={commonStyles.primaryButton}
          onClick={initiateUSDTDepositTxn}
        >
          Continue to Pay
        </Button>
      </div>
    </div>
  );
};

export default EnterINR;
