import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { notification } from "antd";

import TransactionComponent from "../../components/transactions/TransactionComponent";
import styles from "../../styles/transactions/OneOrderTxn.module.scss";
import useAPICall from "../../hooks/useAPICall";
import { getOneTransactionAPI } from "../../apis/transactionsAPIs";

const OneTransactionPage = () => {
  const params = useParams();
  const { get } = useAPICall();
  const [api, contextHolder] = notification.useNotification();

  const txnId = params.txnId;
  const [transaction, setTransaction] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchTransaction = async () => {
    setLoading(true);
    const response = await getOneTransactionAPI(get, txnId);

    if (response.success) {
      setTransaction(response.data);
    } else {
      api["error"]({
        message: "Error",
        description: response.message || "Something went wrong!",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchTransaction();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {contextHolder}
      <div className={styles.pageLayout}>
        <TransactionComponent
          transaction={transaction}
          txnId={txnId}
          loading={loading}
        />
      </div>
    </>
  );
};

export default OneTransactionPage;
