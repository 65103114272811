import { useRef, useState } from "react";
import { Button, Checkbox } from "antd";
import QRCode from "react-qr-code";
import { toBlob } from "html-to-image";
import { BiShareAlt } from "react-icons/bi";
import styles from "../../../styles/portfolio/INRDeposit.module.scss";
import commonStyles from "../../../styles/common/Common.module.scss";

import GpayLogo from "../../../assets/icons/gpay-logo.svg";
import PhonepeLogo from "../../../assets/icons/phonepe-logo.svg";
import PaytmLogo from "../../../assets/icons/paytm-logo.svg";

const PaymentScreen = ({
  inrAmount,
  qrURL,
  loading,
  processUSDTDepositTxn,
}) => {
  const imageRef = useRef(null);
  const [checkboxValue, setCheckboxValue] = useState(false);

  const shareQRCode = async () => {
    const newFile = await toBlob(imageRef.current);

    const data = {
      files: [
        new File([newFile], "nomoex_qr_code.png", {
          type: newFile.type,
        }),
      ],
      title: "Nomoex QR Code",
    };

    await navigator.share(data);
  };

  return (
    <>
      <div className={styles.payableAmountContainer}>
        <p>Payable amount</p>
        <p>{inrAmount}</p>
      </div>
      <div className={styles.qrCodeContainer}>
        {!loading && <QRCode ref={imageRef} value={qrURL} size={180} />}
      </div>
      <div className={styles.shareQRButtonContainer}>
        <Button
          loading={loading}
          onClick={shareQRCode}
          className={styles.shareQRButton}
        >
          <BiShareAlt /> Share QR
        </Button>
      </div>
      <p className={styles.separator}>or</p>
      <div className={styles.paymentMethodContainer}>
        <Button
          className={styles.paymentMethodButton}
          disabled={loading}
          onClick={() => window.location.assign(qrURL)}
        >
          <p className={styles.paymentMethodTitle}>Click and pay via - </p>
          <div className={styles.paymentMethodIcons}>
            <img
              src={GpayLogo}
              alt="gpay-logo"
              className={styles.paymentMethodLogo}
            />
            <img
              src={PhonepeLogo}
              alt="phonepe-logo"
              className={styles.paymentMethodLogo}
            />
            <img
              src={PaytmLogo}
              alt="paytm-logo"
              className={styles.paymentMethodLogo}
            />
          </div>
        </Button>
      </div>
      <div className={styles.paymentButtonContainer}>
        <Checkbox
          value={checkboxValue}
          onChange={(event) => setCheckboxValue(event.target.checked)}
          className={styles.paymentButtonCheckbox}
        >
          I have made the payment
        </Checkbox>
        <Button
          loading={loading}
          disabled={!checkboxValue}
          className={commonStyles.primaryButton}
          onClick={processUSDTDepositTxn}
        >
          Continue
        </Button>
      </div>
    </>
  );
};

export default PaymentScreen;
