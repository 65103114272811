import { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button } from "antd";
import { FaRegUserCircle, FaBars, FaTimes } from "react-icons/fa";
import styles from "../../styles/layout/Header.module.scss";
import commonStyles from "../../styles/common/Common.module.scss";

// import { ReactComponent as HomeIcon } from "../../assets/nav-icons/home.svg";
import { ReactComponent as PortfolioIcon } from "../../assets/nav-icons/portfolio.svg";
import { ReactComponent as ExchangeIcon } from "../../assets/nav-icons/exchange.svg";
import { ReactComponent as TransactionsIcon } from "../../assets/nav-icons/transactions.svg";
import { ReactComponent as SupportIcon } from "../../assets/icons/support.svg";
import googlePlayImg from "../../assets/google-play.jpg";

const Header = ({ whatsAppSupportLink }) => {
  const location = useLocation();
  const { isLoggedIn, userName } = useSelector((state) => state.auth);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  useEffect(() => {
    // execute on location change
    setOpenMobileMenu(false);
  }, [location]);

  return (
    <header className={styles.headerContainer}>
      <div className={styles.header}>
        <div className={styles.logoContainer}>
          <Link to="/exchange">
            <img
              src="https://webapp-nomoex.s3.ap-south-1.amazonaws.com/nomoex_name/nomoex_name.png"
              alt="nomoex-logo"
              className={styles.logo}
            />
          </Link>
          <a
            target="_blank"
            href={
              "https://play.google.com/store/apps/details?id=com.nomoex.nomoex"
            }
            rel="noreferrer"
          >
            <img
              src={googlePlayImg}
              alt="google-play"
              className={styles.googlePlayImg}
            />
          </a>
        </div>
        <div className={styles.navContainer}>
          {!isLoggedIn ? (
            <Link to="/auth/login">
              <Button className={commonStyles.primaryButton}>
                Login/SignUp
              </Button>
            </Link>
          ) : (
            <>
              <nav className={styles.nav}>
                <ul className={styles.navList}>
                  {/* <li className={styles.navListItem}>
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? `${styles.navItem} ${styles.activeNavItem}`
                          : styles.navItem
                      }
                      to="/"
                    >
                      <div className={styles.navItemContainer}>
                        <HomeIcon className={styles.navIcons} />
                        Home
                      </div>
                    </NavLink>
                  </li> */}
                  <li className={styles.navListItem}>
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? `${styles.navItem} ${styles.activeNavItem}`
                          : styles.navItem
                      }
                      to="/portfolio"
                    >
                      <div className={styles.navItemContainer}>
                        <PortfolioIcon className={styles.navIcons} />
                        Portfolio
                      </div>
                    </NavLink>
                  </li>
                  <li className={styles.navListItem}>
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? `${styles.navItem} ${styles.activeNavItem}`
                          : styles.navItem
                      }
                      to="/exchange"
                    >
                      <div className={styles.navItemContainer}>
                        <ExchangeIcon className={styles.navIcons} />
                        Exchange
                      </div>
                    </NavLink>
                  </li>
                  <li className={styles.navListItem}>
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? `${styles.navItem} ${styles.activeNavItem}`
                          : styles.navItem
                      }
                      to="/transactions"
                    >
                      <div className={styles.navItemContainer}>
                        <TransactionsIcon className={styles.navIcons} />
                        Transactions
                      </div>
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </>
          )}
        </div>
        {isLoggedIn && (
          <div className={styles.profileContainer}>
            <a target="_blank" href={whatsAppSupportLink} rel="noreferrer">
              <SupportIcon className={styles.supportIcon} />
            </a>
            <Link to="/profile">
              {/* <FaRegUserCircle className={styles.userIcon} /> */}
              <div className={styles.userProfileContainer}>
                <p className={styles.userProfileText}>
                  {userName
                    ?.split(" ")
                    .map((word) => word.charAt(0))
                    .join("")}
                </p>
              </div>
            </Link>
          </div>
        )}
        <div className={styles.navContainerMobile}>
          <div className={styles.menuIconContainer}>
            {!openMobileMenu ? (
              <FaBars
                onClick={() => setOpenMobileMenu((prevState) => !prevState)}
                className={styles.menuIcon}
              />
            ) : (
              <FaTimes
                onClick={() => setOpenMobileMenu((prevState) => !prevState)}
                className={styles.menuIcon}
              />
            )}
          </div>
          {openMobileMenu && (
            <nav className={styles.mNav}>
              <ul className={styles.mNavList}>
                {!isLoggedIn ? (
                  <li className={styles.mNavListItem}>
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? `${styles.mNavItem} ${styles.mActiveNavItem}`
                          : styles.mNavItem
                      }
                      to="/auth/login"
                    >
                      Login/SignUp
                    </NavLink>
                  </li>
                ) : (
                  <>
                    {/* <li className={styles.mNavListItem}>
                      <NavLink
                        className={({ isActive }) =>
                          isActive
                            ? `${styles.mNavItem} ${styles.mActiveNavItem}`
                            : styles.mNavItem
                        }
                        to="/"
                      >
                        <div className={styles.navItemContainer}>
                          <HomeIcon className={styles.navIcons} />
                          Home
                        </div>
                      </NavLink>
                    </li> */}
                    <li className={styles.mNavListItem}>
                      <NavLink
                        className={({ isActive }) =>
                          isActive
                            ? `${styles.mNavItem} ${styles.mActiveNavItem}`
                            : styles.mNavItem
                        }
                        to="/portfolio"
                      >
                        <div className={styles.navItemContainer}>
                          <PortfolioIcon className={styles.navIcons} />
                          Portfolio
                        </div>
                      </NavLink>
                    </li>
                    <li className={styles.mNavListItem}>
                      <NavLink
                        className={({ isActive }) =>
                          isActive
                            ? `${styles.mNavItem} ${styles.mActiveNavItem}`
                            : styles.mNavItem
                        }
                        to="/exchange"
                      >
                        <div className={styles.navItemContainer}>
                          <ExchangeIcon className={styles.navIcons} />
                          Exchange
                        </div>
                      </NavLink>
                    </li>
                    <li className={styles.mNavListItem}>
                      <NavLink
                        className={({ isActive }) =>
                          isActive
                            ? `${styles.mNavItem} ${styles.mActiveNavItem}`
                            : styles.mNavItem
                        }
                        to="/transactions"
                      >
                        <div className={styles.navItemContainer}>
                          <TransactionsIcon className={styles.navIcons} />
                          Transactions
                        </div>
                      </NavLink>
                    </li>
                    <li className={styles.mNavListItem}>
                      <NavLink
                        className={({ isActive }) =>
                          isActive
                            ? `${styles.mNavItem} ${styles.mActiveNavItem}`
                            : styles.mNavItem
                        }
                        to="/profile"
                      >
                        <div className={styles.navItemContainer}>
                          <FaRegUserCircle className={styles.navIcons} />
                          Profile
                        </div>
                      </NavLink>
                    </li>
                    <a
                      target="_blank"
                      href={whatsAppSupportLink}
                      className={styles.mNavItem}
                      rel="noreferrer"
                      onClick={() => setOpenMobileMenu(false)}
                    >
                      <li className={styles.mNavListItem}>
                        <div className={styles.navItemContainer}>
                          <SupportIcon className={styles.navIcons} />
                          Support
                        </div>
                      </li>
                    </a>
                  </>
                )}
              </ul>
            </nav>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
