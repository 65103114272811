import { useEffect, useState } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router";
import { TbRefresh } from "react-icons/tb";

import styles from "../../styles/portfolio/Portfolio.module.scss";
import commonStyles from "../../styles/common/Common.module.scss";

// import { ReactComponent as NOMOEX_LOGO } from "../../assets/icons/nomoex-logo-white.svg";

const NomoexWallet = ({
  userPortfolio,
  coinsData,
  loading,
  updatePortfolioHandler,
}) => {
  const navigate = useNavigate();
  const [totalHoldings, setTotalHoldings] = useState(0);

  const depositHandler = () => {
    navigate("/portfolio/deposit");
  };

  const withdrawHandler = () => {
    navigate("/portfolio/withdraw");
  };

  useEffect(() => {
    setTotalHoldings(
      (userPortfolio.portfolio?.usdtInfo.holdings || 0) * 1 +
        userPortfolio.portfolio?.coins.reduce(
          (sum, el) =>
            el.coinDetail?.coinSymbol !== "USDT"
              ? sum +
                (el.holdings || 0) *
                  coinsData.find((ele) => ele.id === el.coinId)?.price
              : sum + 0,
          0
        ) +
        (userPortfolio.nomox?.holdings || 0) * 0.05
    );
  }, [userPortfolio, coinsData]);

  return (
    <>
      <div className={styles.walletBackground}>
        <div className={styles.walletContainer}>
          <div className={styles.walletHeader}>
            <div className={styles.logoContainer}>
              {/* <NOMOEX_LOGO className={styles.nomoexLogo} /> */}
              <img
                src="https://webapp-nomoex.s3.ap-south-1.amazonaws.com/nomoex_name/nomoex_name.png"
                alt="nomoex-logo"
                className={styles.nomoexLogo}
              />
              <p className={styles.walletTitle}>wallet</p>
            </div>
            <TbRefresh
              className={styles.refreshButton}
              onClick={updatePortfolioHandler}
            />
          </div>
          <div className={styles.totalHoldingContainer}>
            <p className={styles.totalHolding}>≈${totalHoldings?.toFixed(2)}</p>
          </div>
          <div className={`${commonStyles.actionButtons} umb-0`}>
            <Button
              className={commonStyles.primaryButton}
              loading={loading}
              onClick={depositHandler}
            >
              Deposit
            </Button>
            <Button
              className={commonStyles.secondaryButton}
              loading={loading}
              onClick={withdrawHandler}
            >
              Withdraw
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NomoexWallet;
