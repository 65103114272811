import { Route, Routes, Navigate } from "react-router-dom";
import TransactionPage from "../pages/transactions/TransactionPage";
import OneOrderPage from "../pages/transactions/OneOrderPage";
import OneTransactionPage from "../pages/transactions/OneTransactionPage";

const TransactionsRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<TransactionPage />} />
      <Route path="/order/:orderId" element={<OneOrderPage />} />
      <Route path="/statement/:txnId" element={<OneTransactionPage />} />
      <Route path="*" element={<Navigate replace to="" />} />
    </Routes>
  );
};

export default TransactionsRoutes;
