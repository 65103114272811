import { Card } from "antd";
import { BiInfoCircle } from "react-icons/bi";
import { MdArrowForwardIos, MdOutlinePrivacyTip } from "react-icons/md";
import { BiNotepad } from "react-icons/bi";
import { FiHelpCircle } from "react-icons/fi";
import styles from "../../styles/profile/Profile.module.scss";

const AboutNomoex = () => {
  return (
    <>
      <a target="_blank" href={"https://nomoex.com/about/"} rel="noreferrer">
        <Card hoverable className={styles.moreInfo_card}>
          <div className={styles.moreInfo_container}>
            <div className={styles.icon_moreInfo_topic}>
              <BiInfoCircle size={21} />
              <h3 className={styles.moreinfo_topic}>About Nomoex</h3>
            </div>
            <MdArrowForwardIos />
          </div>
        </Card>
      </a>
      <a target="_blank" href={"https://nomoex.com/privacy/"} rel="noreferrer">
        <Card hoverable className={styles.moreInfo_card}>
          <div className={styles.moreInfo_container}>
            <div className={styles.icon_moreInfo_topic}>
              <MdOutlinePrivacyTip size={21} />
              <h3 className={styles.moreinfo_topic}>Privacy Policy</h3>
            </div>
            <MdArrowForwardIos />
          </div>
        </Card>
      </a>
      <a target="_blank" href={"https://nomoex.com/terms/"} rel="noreferrer">
        <Card hoverable className={styles.moreInfo_card}>
          <div className={styles.moreInfo_container}>
            <div className={styles.icon_moreInfo_topic}>
              <BiNotepad size={21} />
              <h3 className={styles.moreinfo_topic}>Terms and Conditions</h3>
            </div>
            <MdArrowForwardIos />
          </div>
        </Card>
      </a>
      <a target="_blank" href={"mailto:support@nomoex.com"} rel="noreferrer">
        <Card hoverable className={styles.moreInfo_card}>
          <div className={styles.moreInfo_container}>
            <div className={styles.icon_moreInfo_topic}>
              <FiHelpCircle size={21} />
              <h3 className={styles.moreinfo_topic}>Help Center</h3>
            </div>
            <MdArrowForwardIos />
          </div>
        </Card>
      </a>
    </>
  );
};

export default AboutNomoex;
