import config from "../utils/config";

export const getWhatsAppSupportLinkAPI = async (get, queryParams = {}) => {
  const response = await get(
    `${config.COMMONSERVICE_API_URL}/api/v1/common-services/support/whatsapp`
  );
  return response;
};

export const getUSDTPriceAPI = async (get) => {
  const response = await get(
    `${config.COMMONSERVICE_API_URL}/api/v1/common-services/appdata/usdt-price`
  );
  return response;
};
