import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";

import ChangePasswordForm from "../../components/auth/ChangePasswordForm";
import useAPICall from "../../hooks/useAPICall";
import {
  sendChangePasswordOTPAPI,
  verifyChangePasswordOTPAPI,
  changePasswordAPI,
} from "../../apis/profileAPIs";
import { wait } from "../../utils/helper";

const ChangePasswordPage = () => {
  const { post } = useAPICall();
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [delay, setDelay] = useState(+"30");
  const [loading, setLoading] = useState(false);
  const [changePasswordState, setChangePasswordState] = useState("SENDOTP");
  const [changePasswordData, setChangePasswordData] = useState({
    email: "",
    newPassword: "",
    confirmNewPassword: "",
    otp: "",
    authToken: "",
  });

  const sendPasswordChangeOTPHandler = async () => {
    setLoading(true);
    const data = {
      email: changePasswordData.email,
    };
    const response = await sendChangePasswordOTPAPI(post, data);

    if (response.success) {
      setChangePasswordState("VERIFYOTP");
      api["success"]({
        message: "Success!",
        description: response.message,
      });
    } else {
      api["error"]({
        message: "Error",
        description: response.message || "Something went wrong!",
      });
    }
    setLoading(false);
  };

  const verifyPasswordChangeOTPHandler = async () => {
    setLoading(true);
    const data = {
      email: changePasswordData.email,
      otp: changePasswordData.otp,
    };
    const response = await verifyChangePasswordOTPAPI(post, data);

    if (response.success) {
      setChangePasswordState("SETPASSWORD");
      setChangePasswordData((prevState) => ({
        ...prevState,
        authToken: response.data,
      }));
    } else {
      api["error"]({
        message: "Error",
        description: response.message || "Something went wrong!",
      });
    }
    setLoading(false);
  };

  const changeNewPasswordHandler = async () => {
    setLoading(true);
    const data = {
      password: changePasswordData.newPassword,
      confirmPassword: changePasswordData.confirmNewPassword,
      authToken: changePasswordData.authToken,
    };
    const response = await changePasswordAPI(post, data);

    if (response.success) {
      setChangePasswordData((prevState) => ({
        ...prevState,
        newPassword: "",
        confirmNewPassword: "",
      }));
      api["success"]({
        message: "Password changed Successfully!",
        description: "Please Login again",
      });
      wait(2000).then(() => navigate("/auth/login"));
    } else {
      api["error"]({
        message: "Error",
        description: response.message || "Something went wrong!",
      });
    }
    setLoading(false);
  };

  return (
    <>
      {contextHolder}
      <ChangePasswordForm
        loading={loading}
        changePasswordState={changePasswordState}
        changePasswordData={changePasswordData}
        setChangePasswordData={setChangePasswordData}
        sendPasswordChangeOTPHandler={sendPasswordChangeOTPHandler}
        verifyPasswordChangeOTPHandler={verifyPasswordChangeOTPHandler}
        changeNewPasswordHandler={changeNewPasswordHandler}
        delay={delay}
        setDelay={setDelay}
      />
    </>
  );
};

export default ChangePasswordPage;
