import styles from "../../styles/exchange/CoinPage.module.scss";

const UserHoldings = ({ coinData, coinPrice, userHoldings }) => {
  return (
    <>
      <h3 className={styles.aboutCoin_heading}>Your Holdings</h3>
      <div className={styles.holding_cont}>
        <img
          className={styles.coinImg}
          src={coinData.coinIcon}
          alt={`${coinData.coinName} icon`}
        />
        <div className={styles.coinHoldingProperties}>
          <div className={styles.coinHoldingProperty}>
            <p className={styles.coinPropertyTitle}>
              Available {coinData.coinSymbol}
            </p>
            <p className={styles.coinPropertyValue}>
              {userHoldings?.toFixed(6)} {coinData.coinSymbol}
            </p>
          </div>
          <div className={styles.coinHoldingProperty}>
            <p className={styles.coinPropertyTitle}>Net Amount</p>
            <p className={styles.coinPropertyValue}>
              $
              {coinData.coinSymbol === "NOMOX"
                ? userHoldings * coinData.price
                : (userHoldings * coinPrice.price)?.toFixed(4)}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserHoldings;
