import moment from "moment";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styles from "../../styles/exchange/CoinPage.module.scss";

import { ReactComponent as GainIcon } from "../../assets/icons/gain-arrow.svg";
import { ReactComponent as LossIcon } from "../../assets/icons/loss-arrow.svg";

const coinDetails = ({ coinData, coinPrice }) => {
  return (
    <div className={styles.coinDetailsContainer}>
      <div className={styles.img_wishlist_cont}>
        <img
          className={styles.coinImg}
          src={coinData.coinIcon}
          alt={`${coinData.coinName} icon`}
        />
        {/* <Button>
            <FaRegStar />
          </Button> */}
      </div>
      <p className={styles.coinName}>
        {coinData.coinName}/{coinData.coinSymbol}
      </p>
      <div>
        <div>
          {coinData.coinSymbol === "NOMOX" ? (
            <>
              <p className={styles.coinValue}>
                $
                {moment(coinData.disEndDate) > moment() ? (
                  <>
                    {coinData.disPrice}{" "}
                    <span className={styles.offerValue}>{coinData.price}</span>
                  </>
                ) : (
                  coinData.price
                )}
              </p>
              <p className={`${styles.presaleMsg}`}>Presale Ongoing</p>
            </>
          ) : (
            <>
              <p className={styles.coinValue}>
                {coinPrice.price ? (
                  `$${Number(coinPrice.price).toFixed(2)}`
                ) : (
                  <Skeleton style={{ width: 150 }} />
                )}
              </p>
              <p
                className={`${styles.priceChange} ${
                  Number(coinPrice.priceChangePercent) >= 0
                    ? "u-green"
                    : "u-red"
                }`}
              >
                {coinPrice.priceChangePercent ? (
                  <>
                    {Number(coinPrice.priceChangePercent) >= 0 ? (
                      <GainIcon />
                    ) : (
                      <LossIcon />
                    )}{" "}
                    {Number(coinPrice.priceChangePercent).toFixed(2)}%{" "}
                    <span className={styles.changePeriod}>24h</span>
                  </>
                ) : (
                  <Skeleton style={{ width: 100 }} />
                )}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default coinDetails;
