import { Button } from "antd";
import styles from "../../styles/exchange/CoinPage.module.scss";
import commonStyles from "../../styles/common/Common.module.scss";

const CoinActions = ({
  coinData,
  userHoldings,
  buyButtonHandler,
  sellButtonHandler,
  loading,
}) => {
  return (
    <div className={styles.buy_sell_cont}>
      {/* <div className={styles.coinprice}>
        <p className={styles.priceText}>Current Bitcoin Buy Price</p>
        <p className={styles.price}>$81.95</p>
      </div> */}
      <div
        className={`${commonStyles.actionButtons} ${styles.buttonContainer}`}
      >
        <Button
          loading={loading}
          className={commonStyles.primaryButton}
          onClick={buyButtonHandler}
        >
          {userHoldings > 0 ? "Buy more" : "Buy"}
        </Button>
        {coinData.coinSymbol !== "NOMOX" && userHoldings && userHoldings > 0 ? (
          <Button
            loading={loading}
            className={commonStyles.secondaryButton}
            onClick={sellButtonHandler}
          >
            Sell
          </Button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default CoinActions;
