import moment from "moment";
import { Button } from "antd";
import styles from "../../styles/transactions/OneOrderTxn.module.scss";
import commonStyles from "../../styles/common/Common.module.scss";
import PageHeading from "../common/PageHeading";

const OrderComponent = ({ orderDetail, cancelOrderHandler, loading }) => {
  return (
    <>
      <PageHeading heading={"Preview Order"} />
      <div className={styles.orderDetailsContainer}>
        <div className={styles.orderHeader}>
          <div className={styles.coinDetails}>
            <img
              src={orderDetail.coinDetail?.coinIcon}
              alt={`${orderDetail.coinDetail?.coinSymbol} logo`}
              className={styles.coinIcon}
            />
            <p className={styles.coinSymbol}>
              {orderDetail.coinDetail?.coinName || ""}/
              {orderDetail.coinDetail?.coinSymbol || ""}
            </p>
          </div>
          {orderDetail.orderStatus === "S" ? (
            <p className={`${styles.successOrderStatus} u-green`}>
              {orderDetail.orderType === "buy" ||
              orderDetail.orderType === "nomox"
                ? "Buy executed"
                : orderDetail.orderType === "sell"
                ? "Sell executed"
                : ""}
            </p>
          ) : orderDetail.orderStatus === "F" ? (
            <p className={`${styles.failedOrderStatus} u-red`}>
              {orderDetail.orderType === "buy" ||
              orderDetail.orderType === "nomox"
                ? "Buy failed"
                : orderDetail.orderType === "sell"
                ? "Sell failed"
                : ""}
            </p>
          ) : (
            orderDetail.orderStatus === "IP" && (
              <p className={`${styles.processingOrderStatus}`}>
                {orderDetail.orderType === "buy" ||
                orderDetail.orderType === "nomox"
                  ? "Buy"
                  : orderDetail.orderType === "sell"
                  ? "Sell"
                  : ""}
              </p>
            )
          )}
        </div>
        <div className={styles.orderProperty}>
          <p className={styles.orderPropertyTitle}>Type</p>
          <p className={styles.orderPropertyValue}>
            {orderDetail.isLimit ? "Limit Order" : "Instand Order"}
          </p>
        </div>
        <div className={styles.orderProperty}>
          <p className={styles.orderPropertyTitle}>Placed on</p>
          <p className={styles.orderPropertyValue}>
            {moment(orderDetail.orderDate).format("DD MMMM YYYY, hh:mm a")}
          </p>
        </div>
        <div className={styles.orderProperty}>
          <p className={styles.orderPropertyTitle}>Asset Value</p>
          <p className={styles.orderPropertyValue}>
            {orderDetail.holdings} {orderDetail.coinDetail?.coinSymbol || ""}
          </p>
        </div>
        <div className={styles.orderProperty}>
          <p className={styles.orderPropertyTitle}>At Price</p>
          <p className={styles.orderPropertyValue}>${orderDetail.orderPrice}</p>
        </div>
        <div className={styles.orderProperty}>
          <p className={styles.orderPropertyTitle}>Total</p>
          <p className={styles.orderPropertyValue}>
            ${orderDetail.totalAmount}
          </p>
        </div>
        <hr />
        <div className={styles.orderProperty}>
          <p className={styles.orderPropertyTitle}>Fee</p>
          <p className={styles.orderPropertyValue}>${orderDetail.fee}</p>
        </div>
      </div>
      <br />
      {orderDetail.orderStatus === "IP" && (
        <Button
          className={commonStyles.secondaryButton}
          loading={loading}
          onClick={cancelOrderHandler}
        >
          Cancel Order
        </Button>
      )}
    </>
  );
};

export default OrderComponent;
