import moment from "moment";
import countryCodes from "../utils/countryCodes";

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const urlRegex = new RegExp(
  "^(https?:\\/\\/)?" + // validate protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
    "(\\#[-a-z\\d_]*)?$",
  "i"
); // validate fragment locator

export const sendLoginOTP = (data) => {
  if (
    !data.email ||
    !data.email.toString().toLowerCase().trim().match(emailRegex)
  ) {
    return {
      success: false,
      message: "Invalid email address.",
    };
  }
  if (!data.password || data.password.length < 6) {
    return {
      success: false,
      message: "Invalid password.",
    };
  }
  return { success: true };
};

export const verifyOTP = (data) => {
  if (
    !data.email ||
    !data.email.toString().toLowerCase().trim().match(emailRegex)
  ) {
    return {
      success: false,
      message: "Invalid email address.",
    };
  }
  if (
    !data.otp ||
    Number.isNaN(Number(data.otp)) ||
    Number(data.otp) < 1000 ||
    Number(data.otp) > 9999
  ) {
    return {
      success: false,
      message: "Invalid OTP.",
    };
  }
  return { success: true };
};

export const sendSignupOTP = (data) => {
  if (
    !data.email ||
    !data.email.toString().toLowerCase().trim().match(emailRegex)
  ) {
    return {
      success: false,
      message: "Invalid email address.",
    };
  }
  return { success: true };
};

export const signUp = (data) => {
  if (!data.name || data.name.length < 3) {
    return {
      success: false,
      message: "Invalid name.",
    };
  }
  if (!data.password || data.password.length < 6) {
    return {
      success: false,
      message: "Password should have atleast 6 characters.",
    };
  }
  if (
    !data.email ||
    !data.email.toString().toLowerCase().trim().match(emailRegex)
  ) {
    return {
      success: false,
      message: "Invalid email address.",
    };
  }
  if (!data.phone || Number.isNaN(Number(data.phone))) {
    return {
      success: false,
      message: "Invalid OTP.",
    };
  }
  if (!data.country || data.country.length < 3) {
    return {
      success: false,
      message: "Invalid country.",
    };
  }
  if (!data.countryCode || !countryCodes.includes(data.countryCode)) {
    return {
      success: false,
      message: "Invalid country code.",
    };
  }
  if (
    !data.otp ||
    Number.isNaN(Number(data.otp)) ||
    Number(data.otp) < 1000 ||
    Number(data.otp) > 9999
  ) {
    return {
      success: false,
      message: "Invalid OTP.",
    };
  }
  if (
    data.hasOwnProperty("dateOfBirth") &&
    !moment(new Date(data.dateOfBirth)).isValid()
  ) {
    return { success: false, message: "Invalid date of birth" };
  }
  if (
    data.hasOwnProperty("nickname") &&
    (!data.nickname || data.nickname.length < 3 || data.nickname.length > 8)
  ) {
    return {
      success: false,
      message: "Invalid nickname.",
    };
  }
  if (
    data.hasOwnProperty("avatar") &&
    (!data.avatar || !urlRegex.test(data.avatar))
  ) {
    return {
      success: false,
      message: "Invalid avatar",
    };
  }
  return { success: true };
};

export const sendOTPforPassword = (data) => {
  if (
    !data.email ||
    !data.email.toString().toLowerCase().trim().match(emailRegex)
  ) {
    return {
      success: false,
      message: "Invalid email address.",
    };
  }
  return { success: true };
};

export const verifyOTPforPassword = (data) => {
  if (
    !data.email ||
    !data.email.toString().toLowerCase().trim().match(emailRegex)
  ) {
    return {
      success: false,
      message: "Invalid email address.",
    };
  }
  if (
    !data.otp ||
    Number.isNaN(Number(data.otp)) ||
    Number(data.otp) < 1000 ||
    Number(data.otp) > 9999
  ) {
    return {
      success: false,
      message: "Invalid OTP.",
    };
  }
  return { success: true };
};

export const changePassword = (data) => {
  if (!data.password || data.password.length < 6) {
    return {
      success: false,
      message: "Invalid password.",
    };
  }
  if (data.confirmPassword !== data.password) {
    return {
      success: false,
      message: "Password does not match",
    };
  }
  if (!data.authToken || data.authToken.length < 6) {
    return {
      success: false,
      message: "Invalid auth token.",
    };
  }
  return { success: true };
};
