import { Link } from "react-router-dom";
import styles from "../../styles/transactions/Transaction.module.scss";
import moment from "moment";
import { Button } from "antd";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

const StatementTabs = ({
  transactions,
  totalTransactions,
  queryParamsTransactions,
  updateTransactionsQueryHandler,
  loading,
}) => {
  const previousHandler = () => {
    const page =
      queryParamsTransactions.pagination.page === 1
        ? 1
        : queryParamsTransactions.pagination.page - 1;

    updateTransactionsQueryHandler(page);
  };

  const nextHandler = () => {
    const page =
      totalTransactions <
      queryParamsTransactions.pagination.page *
        queryParamsTransactions.pagination.limit
        ? queryParamsTransactions.pagination.page
        : queryParamsTransactions.pagination.page + 1;

    updateTransactionsQueryHandler(page);
  };

  return (
    <>
      {transactions.map((el, i) => (
        <Link key={i} to={`/transactions/statement/${el.txnId}`}>
          <div className={styles.transactionCard}>
            <div className={styles.transactionDetails}>
              <img
                className={styles.coinIcon}
                src={
                  el.txnType === "vInvest" || el.txnType === "vRedeem"
                    ? "https://webapp-nomoex.s3.ap-south-1.amazonaws.com/vaultx/vaultx-icon.png"
                    : el.coinDetail?.coinIcon
                }
                alt={`${el.coinDetail?.coinSymbol} logo`}
              />
              <div>
                <p className={styles.transactionTitle}>
                  {el.txnType === "deposit"
                    ? `${el.coinDetail?.coinSymbol} deposit`
                    : el.txnType === "withdraw"
                    ? `${el.coinDetail?.coinSymbol} withdraw`
                    : el.txnType === "vInvest"
                    ? `Vaultx investment`
                    : el.txnType === "vRedeem"
                    ? `Vaultx redeem`
                    : ""}
                </p>
                <p className={styles.transactionDate}>
                  {moment(el.txnDate).format("DD MMMM YYYY, hh:mm a")}
                </p>
              </div>
            </div>
            <div className={styles.transactionStatus}>
              <p className={styles.transactionAmount}>${el.txnAmount}</p>
              {el.txnStatus === "S" ? (
                <p className={`${styles.transactionStatus} u-green`}>
                  Completed
                </p>
              ) : el.txnStatus === "F" ? (
                <p className={`${styles.transactionStatus} u-red`}>Failed</p>
              ) : el.txnStatus === "IP" ? (
                <p className={`${styles.transactionStatus}`}>Processing</p>
              ) : el.txnStatus === "IN" ? (
                <p className={`${styles.transactionStatus}`}>Initiated</p>
              ) : (
                ""
              )}
            </div>
          </div>
        </Link>
      ))}
      <div className={styles.paginationContainer}>
        {queryParamsTransactions.pagination.page > 1 && (
          <Button className={styles.previousButton} onClick={previousHandler}>
            <BsArrowLeft /> Previous
          </Button>
        )}
        {totalTransactions >
          queryParamsTransactions.pagination.page *
            queryParamsTransactions.pagination.limit && (
          <Button className={styles.nextButton} onClick={nextHandler}>
            Next <BsArrowRight />
          </Button>
        )}
      </div>
    </>
  );
};

export default StatementTabs;
