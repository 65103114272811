import { Link } from "react-router-dom";
import InputPassword from "../common/InputPassword";
import InputText from "../common/InputText";
import { Button } from "antd";

import styles from "../../styles/auth/auth.module.scss";
import commonStyles from "../../styles/common/Common.module.scss";
import NumberInput from "../common/NumberInput";
import InputSelect from "../common/InputSelect";
import countryWithCode from "../../utils/countryWithCode";

const SignupForm = ({
  loading,
  signupState,
  signupData,
  setSignupData,
  sendOTPHandler,
  verifyOTPHandler,
}) => {
  return (
    <div className={styles.formContainer}>
      <div>
        <h1>Sign-up</h1>
        <hr />
      </div>
      <div>
        {signupState === "SENDOTP" && (
          <>
            <div className={styles.formInputContainer}>
              <p className={styles.formInputTitle}>Name</p>
              <InputText
                className={styles.formInputBox}
                value={signupData.name}
                onChange={(e) =>
                  setSignupData((prevState) => ({
                    ...prevState,
                    name: e.target.value,
                  }))
                }
              />
            </div>
            <div className={styles.formInputContainer}>
              <div className={styles.formInputTitleContainer}>
                <p className={styles.formInputTitle}>Create a Password</p>
              </div>
              <InputPassword
                className={styles.formInputBox}
                value={signupData.password}
                onChange={(e) =>
                  setSignupData((prevState) => ({
                    ...prevState,
                    password: e.target.value,
                  }))
                }
              />
            </div>
            <div className={styles.formInputContainer}>
              <p className={styles.formInputTitle}>Email</p>
              <InputText
                className={styles.formInputBox}
                value={signupData.email}
                onChange={(e) =>
                  setSignupData((prevState) => ({
                    ...prevState,
                    email: e.target.value,
                  }))
                }
              />
            </div>
            <div className={styles.formInputContainer}>
              <p className={styles.formInputTitle}>Country</p>
              <InputSelect
                className={styles.formInputBox}
                value={signupData.country}
                onChange={(value) => {
                  const country = countryWithCode.find(
                    (el) => el.name === value
                  );
                  setSignupData((prevState) => ({
                    ...prevState,
                    country: country.name,
                    countryCode: country.dial_code,
                  }));
                }}
                options={countryWithCode.map((el) => ({
                  value: el.name,
                  label: `${el.name} - ${el.code}`,
                }))}
                allowClear={false}
              />
            </div>
            <div className={styles.formInputContainer}>
              <p className={styles.formInputTitle}>Mobile No.</p>
              <div className={styles.multiInputContainer}>
                <InputSelect
                  className={styles.formInputBox}
                  style={{ width: 110 }}
                  value={signupData.countryCode}
                  onChange={(value) => {
                    const country = countryWithCode.find(
                      (el) => el.dial_code === value
                    );
                    setSignupData((prevState) => ({
                      ...prevState,
                      country: country.name,
                      countryCode: country.dial_code,
                    }));
                  }}
                  options={countryWithCode.map((el) => ({
                    value: el.dial_code,
                    label: el.dial_code,
                  }))}
                  allowClear={false}
                />
                <NumberInput
                  className={styles.formInputBox}
                  controls={false}
                  value={signupData.phone}
                  onChange={(value) =>
                    setSignupData((prevState) => ({
                      ...prevState,
                      phone: +value,
                    }))
                  }
                />
              </div>
            </div>
          </>
        )}
        {signupState === "VERIFYOTP" && (
          <>
            <div className={styles.formInputContainer}>
              <p className={styles.formInputTitle}>
                Enter OTP sent on {signupData.email}
              </p>
              <NumberInput
                className={styles.formInputBox}
                controls={false}
                value={signupData.otp}
                onChange={(value) =>
                  setSignupData((prevState) => ({
                    ...prevState,
                    otp: +value,
                  }))
                }
              />
            </div>
            <p className={styles.message}>
              Didn't recieve?{" "}
              <span
                className={styles.requestAgain}
                onClick={loading ? () => {} : sendOTPHandler}
              >
                Request Again
              </span>
              .
            </p>
          </>
        )}
      </div>
      <div>
        {signupState === "SENDOTP" && (
          <>
            <p className={styles.message}>
              By Continue, you agree to our{" "}
              <a
                target="_blank"
                href={"https://nomoex.com/terms/"}
                rel="noreferrer"
              >
                Terms and Conditions
              </a>{" "}
              and that you have read our{" "}
              <a
                target="_blank"
                href={"https://nomoex.com/privacy/"}
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              .
            </p>
            <div className={commonStyles.actionButtons}>
              <Button
                loading={loading}
                className={commonStyles.primaryButton}
                onClick={sendOTPHandler}
              >
                Continue
              </Button>
            </div>
            <p className={styles.message}>
              Already have an account?{" "}
              <Link to={loading ? "" : "/auth/login"}>Login Now</Link>.
            </p>
          </>
        )}
        {signupState === "VERIFYOTP" && (
          <>
            <div className={commonStyles.actionButtons}>
              <Button
                loading={loading}
                className={commonStyles.primaryButton}
                onClick={verifyOTPHandler}
              >
                Create an account
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SignupForm;
