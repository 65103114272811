import { AiOutlineSearch } from "react-icons/ai";
import InputText from "../common/InputText";

const ExchangeSearch = ({ searchValue, setSearchValue }) => {
  return (
    <>
      <InputText
        placeholder="Search for your coin"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        prefix={<AiOutlineSearch />}
        className="umb-2"
      />
    </>
  );
};

export default ExchangeSearch;
