import { Button, Radio } from "antd";
import Drawer from "@mui/material/Drawer";

import styles from "../../styles/exchange/CoinPage.module.scss";
import commonStyles from "../../styles/common/Common.module.scss";

import NumberInput from "../common/NumberInput";
// import InputSelect from "../common/InputSelect";

const BuyActions = ({
  coinSymbol,
  open,
  onClose,
  buyData,
  setBuyData,
  loading,
  buyCoinHandler,
}) => {
  const orderTypeOptions = [
    {
      value: "false",
      label: "Instant order",
    },
  ];

  if (coinSymbol !== "NOMOX") {
    orderTypeOptions.push({
      value: "true",
      label: "Limit order",
    });
  }

  return (
    <>
      {/* <Drawer
        title="Buy"
        placement={"bottom"}
        closable={false}
        onClose={onClose}
        open={open}
        key={"bottom"}
        headerStyle={{ textAlign: "center" }}
        height="fit-content"
        zIndex={1011}
      >
        // Code
      </Drawer> */}
      <Drawer anchor={"bottom"} open={open} onClose={onClose}>
        <div className={styles.formContainer}>
          <h3 className={styles.drawerHeading}>Buy</h3>
          <hr />
          <div className={styles.formInputContainer}>
            <p className={styles.formInputTitle}>Enter $ amount</p>
            <NumberInput
              className={styles.formInputBox}
              controls={false}
              placeholder={"$0.0"}
              value={buyData.amount}
              onChange={(value) =>
                setBuyData((prevState) => ({
                  ...prevState,
                  amount: +value,
                }))
              }
            />
          </div>
          <div className={styles.formInputContainer}>
            <p className={styles.formInputTitle}>Order type</p>
            {/* <InputSelect
              className={styles.formInputBox}
              value={`${buyData.limit}`}
              onChange={(value) => {
                setBuyData((prevState) => ({
                  ...prevState,
                  limit: value === "true",
                }));
              }}
              options={orderTypeOptions}
              allowClear={false}
              showSearch={false}
            /> */}
            <Radio.Group
              options={orderTypeOptions}
              onChange={(e) =>
                setBuyData((prevState) => ({
                  ...prevState,
                  limit: e.target.value === "true",
                }))
              }
              value={`${buyData.limit}`}
              className={styles.formRadioBox}
              optionType="button"
              size="large"
              buttonStyle="solid"
            />
          </div>
          {buyData.limit && (
            <>
              <div className={styles.formInputContainer}>
                <p className={styles.formInputTitle}>Enter limit price</p>
                <NumberInput
                  className={styles.formInputBox}
                  controls={false}
                  placeholder={"$0.0"}
                  value={buyData.limitAmount}
                  onChange={(value) =>
                    setBuyData((prevState) => ({
                      ...prevState,
                      limitAmount: +value,
                    }))
                  }
                />
              </div>
              <Radio.Group
                options={[
                  {
                    label: "1d",
                    value: "1d",
                  },
                  {
                    label: "7d",
                    value: "7d",
                  },
                  {
                    label: "30d",
                    value: "30d",
                  },
                ]}
                onChange={(e) =>
                  setBuyData((prevState) => ({
                    ...prevState,
                    limitPeriod: e.target.value,
                  }))
                }
                value={buyData.limitPeriod}
                optionType="button"
                className={styles.formRadioBox}
              />
            </>
          )}
          <div className={commonStyles.actionButtons}>
            <Button
              loading={loading}
              className={commonStyles.secondaryButton}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              loading={loading}
              className={commonStyles.primaryButton}
              onClick={buyCoinHandler}
            >
              Buy
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default BuyActions;
