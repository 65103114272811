import { Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ExchangeRoutes from "./ExchangeRoutes";
import TransactionsRoutes from "./TransactionsRoutes";
import PortfolioRoutes from "./PortfolioRoutes";
import ProfileRoutes from "./ProfileRoutes"
import AuthRoutes from "./AuthRoutes";

const IndexRoute = () => {
  const { isLoggedIn } = useSelector((state) => state.auth);

  return (
    <>
      {isLoggedIn ? (
        <Routes>
          <Route path="/" element={<Navigate replace to="exchange" />} />
          <Route path="/portfolio/*" element={<PortfolioRoutes/>} />
          <Route path="/exchange/*" element={<ExchangeRoutes />} />
          <Route path="/transactions/*" element={<TransactionsRoutes />} />
          <Route path="/profile/*" element={<ProfileRoutes/>} />
          <Route path="*" element={<Navigate replace to="exchange" />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/auth/*" element={<AuthRoutes />} />
          <Route path="*" element={<Navigate replace to="auth" />} />
        </Routes>
      )}
    </>
  );
};

export default IndexRoute;
