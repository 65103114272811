import config from "../utils/config";
import { createQueryString } from "../utils/helper";
import { usdtDeposit } from "../validators/portfolioValidator";

export const getAllTransactionsAPI = async (get, queryParams) => {
  const queryString = createQueryString(queryParams);
  const response = await get(
    `${config.ORDERS_API_URL}/api/v1/orders/transaction${queryString}`
  );
  return response;
};

export const getOneTransactionAPI = async (get, txnId) => {
  const response = await get(
    `${config.ORDERS_API_URL}/api/v1/orders/transaction/${txnId}`
  );
  return response;
};

export const initiateUSDTDepositAPI = async (post, data) => {
  const validate = usdtDeposit(data);

  if (validate.success) {
    const response = await post(
      `${config.ORDERS_API_URL}/api/v1/orders/fiat/initiate-usdt`,
      data
    );

    return response;
  } else {
    return validate;
  }
};

export const proccessDepositAPI = async (patch, txnId) => {
  const response = await patch(
    `${config.ORDERS_API_URL}/api/v1/orders/fiat/process/${txnId}`
  );

  return response;
};
