import { useState } from "react";
import { notification } from "antd";

import LoginForm from "../../components/auth/LoginForm";
import styles from "../../styles/auth/auth.module.scss";
import useAPICall from "../../hooks/useAPICall";
import { loginAPI, sendLoginOTPAPI } from "../../apis/profileAPIs";

const LoginPage = () => {
  const { post } = useAPICall();
  const [api, contextHolder] = notification.useNotification();

  const [loading, setLoading] = useState(false);
  const [loginState, setLoginState] = useState("SENDOTP");
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
    otp: "",
  });

  const sendOTPHandler = async () => {
    setLoading(true);
    const data = {
      email: loginData.email,
      password: loginData.password,
    };
    const response = await sendLoginOTPAPI(post, data);

    if (response.success) {
      setLoginState("VERIFYOTP");
    } else {
      api["error"]({
        message: "Error",
        description: response.message || "Something went wrong!",
      });
    }
    setLoading(false);
  };

  const verifyOTPHandler = async () => {
    setLoading(true);
    const data = {
      email: loginData.email,
      otp: loginData.otp,
      notifyToken: "",
    };
    const response = await loginAPI(post, data);

    if (response.success) {
      setLoginData({
        email: "",
        password: "",
        otp: "",
      });
    } else {
      api["error"]({
        message: "Error",
        description: response.message || "Something went wrong!",
      });
    }
    setLoading(false);
  };

  return (
    <>
      {contextHolder}
      <div className={styles.pageLayout}>
        <div className={styles.container}>
          {/* <div className={styles.imageContainer}>Image</div> */}
          <LoginForm
            loading={loading}
            loginState={loginState}
            loginData={loginData}
            setLoginData={setLoginData}
            sendOTPHandler={sendOTPHandler}
            verifyOTPHandler={verifyOTPHandler}
          />
        </div>
      </div>
    </>
  );
};

export default LoginPage;
