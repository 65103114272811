import { useState } from "react";
import styles from "../../styles/portfolio/INRDeposit.module.scss";
import PageHeading from "../../components/common/PageHeading";
import EnterINR from "../../components/portfolio/inrDeposit/EnterINR";
import useAPICall from "../../hooks/useAPICall";
import { notification } from "antd";
import { getUSDTPriceAPI } from "../../apis/commonServicesAPIs";
import { useEffect } from "react";
import {
  initiateUSDTDepositAPI,
  proccessDepositAPI,
} from "../../apis/transactionsAPIs";
import PaymentScreen from "../../components/portfolio/inrDeposit/PaymentScreen";
import SuccessScreen from "../../components/portfolio/inrDeposit/SuccessScreen";

const INRDepositPage = () => {
  const { get, post, patch } = useAPICall();
  const [api, contextHolder] = notification.useNotification();

  const [depositState, setDepositState] = useState("ENTERAMOUNT"); // ENTERAMOUNT, PAYMENTPROCESS, SUCCESSSCREEN
  const [usdtPrice, setUSDTPrice] = useState(0);
  const [inrAmount, setINRAmount] = useState(0);
  const [txnId, setTxnId] = useState("");
  const [qrURL, setQRURL] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchUSDTPrice = async () => {
    setLoading(true);
    const response = await getUSDTPriceAPI(get);

    if (response.success) {
      setUSDTPrice(response.data);
    } else {
      api["error"]({
        message: "Error",
        description: response.message || "Something went wrong!",
      });
    }
    setLoading(false);
  };

  const initiateUSDTDepositTxn = async () => {
    setLoading(true);
    const data = {
      amount: inrAmount,
    };
    const response = await initiateUSDTDepositAPI(post, data);

    if (response.success) {
      setTxnId(response.data.txnId);
      setQRURL(response.data.qrUrl);
      setDepositState("PAYMENTPROCESS");
    } else {
      api["error"]({
        message: "Error",
        description: response.message || "Something went wrong!",
      });
    }
    setLoading(false);
  };

  const processUSDTDepositTxn = async () => {
    setLoading(true);
    if (!txnId) {
      api["error"]({
        message: "Error",
        description: "Something went wrong!",
      });
    } else {
      const response = await proccessDepositAPI(patch, txnId);

      if (response.success) {
        setINRAmount(0);
        setTxnId("");
        setQRURL("");
        setDepositState("SUCCESSSCREEN");
      } else {
        api["error"]({
          message: "Error",
          description: response.message || "Something went wrong!",
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchUSDTPrice();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {contextHolder}
      <div className={styles.container}>
        <PageHeading heading={"INR Deposit"} />
        {depositState === "ENTERAMOUNT" && (
          <EnterINR
            usdtPrice={usdtPrice}
            loading={loading}
            inrAmount={inrAmount}
            setINRAmount={setINRAmount}
            initiateUSDTDepositTxn={initiateUSDTDepositTxn}
          />
        )}
        {depositState === "PAYMENTPROCESS" && (
          <PaymentScreen
            inrAmount={inrAmount}
            loading={loading}
            qrURL={qrURL}
            processUSDTDepositTxn={processUSDTDepositTxn}
          />
        )}
        {depositState === "SUCCESSSCREEN" && <SuccessScreen />}
      </div>
    </>
  );
};

export default INRDepositPage;
