// import { Tabs as AntDTabs } from "antd";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState } from "react";

import CoinComponent from "./CoinComponent";
import Spinner from "../common/Spinner";

import styles from "../../styles/exchange/Exchange.module.scss";
import "../../styles/common/packageCSS.scss";

const CoinsList = ({ categories, coinsData, searchValue }) => {
  const [activeKey, setActiveKey] = useState(0);
  const [catPath, setCatPath] = useState("allCoins");

  const coinsDetails = coinsData[catPath]
    ? catPath === "topGainers"
      ? coinsData[catPath]
          .filter(
            (coin) =>
              coin.coinSymbol
                .toLowerCase()
                .includes(searchValue.toLowerCase()) ||
              coin.coinName.toLowerCase().includes(searchValue.toLowerCase())
          )
          .sort(
            (coin1, coin2) =>
              Number(coin2.priceChangePercent) -
              Number(coin1.priceChangePercent)
          )
      : catPath === "topLosers"
      ? coinsData[catPath]
          .filter(
            (coin) =>
              coin.coinSymbol
                .toLowerCase()
                .includes(searchValue.toLowerCase()) ||
              coin.coinName.toLowerCase().includes(searchValue.toLowerCase())
          )
          .sort(
            (coin1, coin2) =>
              Number(coin1.priceChangePercent) -
              Number(coin2.priceChangePercent)
          )
      : coinsData[catPath]
          .filter(
            (coin) =>
              coin.coinSymbol
                .toLowerCase()
                .includes(searchValue.toLowerCase()) ||
              coin.coinName.toLowerCase().includes(searchValue.toLowerCase())
          )
          .sort((coin1, coin2) => Number(coin2.price) - Number(coin1.price))
          .sort((coin1, coin2) =>
            coin1.coinSymbol === "NOMOX"
              ? -1
              : coin2.coinSymbol === "NOMOX"
              ? 1
              : 0
          )
    : [];

  // const items = categories.map((cat) => ({
  //   key: cat.position,
  //   label: cat.catName,
  //   children:
  //     coinsDetails.length > 0 ? (
  //       coinsDetails.map((coin, i) => (
  //         <div key={i}>
  //           {cat.catPath === "allCoins" && coin.coinSymbol === "NOMOX" && (
  //             <CoinComponent coin={coin} />
  //           )}
  //           {coin.coinSymbol !== "NOMOX" && <CoinComponent coin={coin} />}
  //         </div>
  //       ))
  //     ) : (
  //       <Spinner />
  //     ),
  // }));

  // const tabChangeHandler = (key) => {
  //   setActiveKey(key);
  //   const catPath =
  //     categories.find((cat) => cat.position === Number(key))?.catPath ||
  //     "allCoins";
  //   setCatPath(catPath);
  // };

  const renderCoinDetails = () => {
    return coinsDetails.length > 0 ? (
      coinsDetails.map((coin, i) => (
        <div key={i}>
          {catPath === "allCoins" && coin.coinSymbol === "NOMOX" && (
            <CoinComponent coin={coin} />
          )}
          {coin.coinSymbol !== "NOMOX" && <CoinComponent coin={coin} />}
        </div>
      ))
    ) : (
      <Spinner />
    );
  };

  const tabChangeHandler = (event, key) => {
    setActiveKey(key);
    const catPath =
      categories.find((cat) => cat.position - 1 === Number(key))?.catPath ||
      "allCoins";
    setCatPath(catPath);
  };

  return (
    <>
      <Tabs
        value={activeKey}
        onChange={tabChangeHandler}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        className={styles.tabBarStyle}
      >
        {categories.map((el, i) => (
          <Tab key={i} label={el.catName} className={styles.tabStyle} />
        ))}
      </Tabs>
      {renderCoinDetails()}
      {/* <AntDTabs
        // defaultActiveKey={1}
        items={items}
        activeKey={activeKey}
        onChange={tabChangeHandler}
      /> */}
    </>
  );
};

export default CoinsList;
