import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { notification } from "antd";

import OrderComponent from "../../components/transactions/OrderComponent";
import styles from "../../styles/transactions/OneOrderTxn.module.scss";
import useAPICall from "../../hooks/useAPICall";
import { getOneOrderHistoryAPI } from "../../apis/orderHistoriesAPIs";
import { cancelCoinOrderAPI } from "../../apis/portfolioAPIs";

const OneOrderPage = () => {
  const params = useParams();
  const { get, post } = useAPICall();
  const [api, contextHolder] = notification.useNotification();

  const orderId = params.orderId;
  const [orderDetail, setOrderDetail] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchOrderDetails = async () => {
    setLoading(true);
    const response = await getOneOrderHistoryAPI(get, orderId);

    if (response.success) {
      setOrderDetail(response.data);
    } else {
      api["error"]({
        message: "Error",
        description: response.message || "Something went wrong!",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchOrderDetails();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cancelOrderHandler = async () => {
    const response = await cancelCoinOrderAPI(post, { orderId: orderId });

    if (response.success) {
      api["success"]({
        message: "Success",
        description: response.message,
      });
      await fetchOrderDetails();
    } else {
      api["error"]({
        message: "Error",
        description: response.message || "Something went wrong!",
      });
    }
  };

  return (
    <>
      {contextHolder}
      <div className={styles.pageLayout}>
        <OrderComponent
          orderDetail={orderDetail}
          loading={loading}
          cancelOrderHandler={cancelOrderHandler}
        />
      </div>
    </>
  );
};

export default OneOrderPage;
