import { Link } from "react-router-dom";
import InputPassword from "../common/InputPassword";
import InputText from "../common/InputText";
import { Button } from "antd";

import styles from "../../styles/auth/auth.module.scss";
import commonStyles from "../../styles/common/Common.module.scss";
import NumberInput from "../common/NumberInput";

const LoginForm = ({
  loading,
  loginState,
  loginData,
  setLoginData,
  sendOTPHandler,
  verifyOTPHandler,
}) => {
  return (
    <div className={styles.formContainer}>
      <div>
        <h1>Login</h1>
        <hr />
      </div>
      <div>
        {loginState === "SENDOTP" && (
          <>
            <div className={styles.formInputContainer}>
              <p className={styles.formInputTitle}>Email</p>
              <InputText
                className={styles.formInputBox}
                value={loginData.email}
                onChange={(e) =>
                  setLoginData((prevState) => ({
                    ...prevState,
                    email: e.target.value,
                  }))
                }
              />
            </div>
            <div className={styles.formInputContainer}>
              <div className={styles.formInputTitleContainer}>
                <p className={styles.formInputTitle}>Password</p>
                <Link
                  to={loading ? "" : "/auth/change-password"}
                  className={styles.formInputTitle}
                >
                  Forget Password?
                </Link>
              </div>
              <InputPassword
                className={styles.formInputBox}
                value={loginData.password}
                onChange={(e) =>
                  setLoginData((prevState) => ({
                    ...prevState,
                    password: e.target.value,
                  }))
                }
                onPressEnter={loading ? () => {} : sendOTPHandler}
              />
            </div>
          </>
        )}
        {loginState === "VERIFYOTP" && (
          <>
            <div className={styles.formInputContainer}>
              <p className={styles.formInputTitle}>
                Enter OTP sent on {loginData.email}
              </p>
              <NumberInput
                className={styles.formInputBox}
                controls={false}
                value={loginData.otp}
                onChange={(value) =>
                  setLoginData((prevState) => ({
                    ...prevState,
                    otp: +value,
                  }))
                }
              />
            </div>
            <p className={styles.message}>
              Didn't recieve?{" "}
              <span
                className={styles.requestAgain}
                onClick={loading ? () => {} : sendOTPHandler}
              >
                Request Again
              </span>
              .
            </p>
          </>
        )}
      </div>
      <div>
        {loginState === "SENDOTP" && (
          <>
            <div className={commonStyles.actionButtons}>
              <Button
                loading={loading}
                className={commonStyles.primaryButton}
                onClick={sendOTPHandler}
              >
                Continue
              </Button>
            </div>
            <p className={styles.message}>
              Does not have account? Create one in less than a minute{" "}
              <Link to={loading ? "" : "/auth/signup"}>Register Now</Link>.
            </p>
          </>
        )}
        {loginState === "VERIFYOTP" && (
          <>
            <div className={commonStyles.actionButtons}>
              <Button
                loading={loading}
                className={commonStyles.primaryButton}
                onClick={verifyOTPHandler}
              >
                Login
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LoginForm;
