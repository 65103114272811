import { message } from "antd";
import { TbCopy } from "react-icons/tb";
import styles from "../../styles/profile/Profile.module.scss";
import Profile_Avtar from "../../assets/icons/Profile_Avtar.svg";

const UserProfile = ({ profileData }) => {
  return (
    <div className={styles.profile_cont}>
      <img
        src={Profile_Avtar}
        alt="profile-avatar"
        className={styles.userAvatar}
      />
      <div>
        <h3 className={styles.userName}>{profileData.name}</h3>
        <p className={styles.userEmail}>{profileData.email}</p>
        <div className={styles.copyuid_cont}>
          <h4 className={styles.uid}>UID : {profileData.uid}</h4>
          <TbCopy
            className={styles.copyIcon}
            onClick={async () => {
              await navigator.clipboard.writeText(profileData.uid);
              message.success("UID copied to clipboard");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
