import { Tabs } from "antd";
import "../../styles/common/packageCSS.scss";

import OrderDetails from "./OrderDetails";

const OrderTabs = ({
  openOrders,
  totalOpenOrders,
  queryParamsOpenOrders,
  updateOpenOrdersQueryHandler,
  orderHistories,
  totalOrderHistories,
  queryParamsOrderHistories,
  updateOrderHistoriesQueryHandler,
  loading,
}) => {
  const items = [
    {
      key: "Open_Orders",
      label: `Open Orders`,
      children: (
        <OrderDetails
          orders={openOrders}
          totalOrders={totalOpenOrders}
          queryParams={queryParamsOpenOrders}
          updateOrdersQueryHandler={updateOpenOrdersQueryHandler}
        />
      ),
    },
    {
      key: "History",
      label: `History`,
      children: (
        <OrderDetails
          orders={orderHistories}
          totalOrders={totalOrderHistories}
          queryParams={queryParamsOrderHistories}
          updateOrdersQueryHandler={updateOrderHistoriesQueryHandler}
        />
      ),
    },
  ];

  return (
    <Tabs
      type="card"
      centered
      items={items}
      // onChange={onChange}
    />
  );
};
export default OrderTabs;
