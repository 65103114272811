import { Route, Routes, Navigate } from "react-router-dom";
import ExchangePage from "../pages/exchange/ExchangePage";
import CoinPage from "../pages/exchange/CoinPage";

const ExchangeRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ExchangePage />} />
      <Route path="/coins/:coinSymbol" element={<CoinPage />} />
      <Route path="*" element={<Navigate replace to="" />} />
    </Routes>
  );
};

export default ExchangeRoutes;
