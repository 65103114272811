export const buyCoin = (data) => {
  if (
    !data.coinId ||
    data.coinId.length !== 24 ||
    Number.isNaN(Number("0x" + data.coinId))
  ) {
    return {
      success: false,
      message: "Invalid coin.",
    };
  }
  if (
    !data.amount ||
    Number.isNaN(Number(data.amount)) ||
    Number(data.amount) < 1
  ) {
    return {
      success: false,
      message: "Amount must be equals to or more than $1.",
    };
  }
  if (typeof data.limit !== "boolean") {
    return {
      success: false,
      message: "Limit information is not available.",
    };
  }
  if (
    data.limit &&
    (!data.limitPeriod ||
      (data.limitPeriod !== "1d" &&
        data.limitPeriod !== "7d" &&
        data.limitPeriod !== "30d"))
  ) {
    return {
      success: false,
      message: "Invalid limit period.",
    };
  }
  if (
    data.limit &&
    (!data.limitAmount ||
      Number.isNaN(Number(data.limitAmount)) ||
      Number(data.limitAmount) < 1)
  ) {
    return {
      success: false,
      message: "Invalid limit amount.",
    };
  }
  return { success: true };
};

export const sellCoin = (data) => {
  if (
    !data.coinId ||
    data.coinId.length !== 24 ||
    Number.isNaN(Number("0x" + data.coinId))
  ) {
    return {
      success: false,
      message: "Invalid coin.",
    };
  }
  if (
    !data.quantity ||
    Number.isNaN(Number(data.quantity)) ||
    Number(data.quantity) <= 0
  ) {
    return {
      success: false,
      message: "Invalid quantity.",
    };
  }
  if (typeof data.limit !== "boolean") {
    return {
      success: false,
      message: "Limit information is not available.",
    };
  }
  if (
    data.limit &&
    (!data.limitPeriod ||
      (data.limitPeriod !== "1d" &&
        data.limitPeriod !== "7d" &&
        data.limitPeriod !== "30d"))
  ) {
    return {
      success: false,
      message: "Invalid limit period.",
    };
  }
  if (
    data.limit &&
    (!data.amount ||
      Number.isNaN(Number(data.amount)) ||
      Number(data.amount) < 1)
  ) {
    return {
      success: false,
      message: "Invalid limit amount.",
    };
  }
  return { success: true };
};

export const withdrawToken = (data) => {
  if (
    !data.coinId ||
    data.coinId.length !== 24 ||
    Number.isNaN(Number("0x" + data.coinId))
  ) {
    return {
      success: false,
      message: "Invalid coin.",
    };
  }
  if (
    !data.quantity ||
    Number.isNaN(Number(data.quantity)) ||
    Number(data.quantity) <= 0
  ) {
    return {
      success: false,
      message: "Invalid quantity.",
    };
  }
  if (!data.address || data.address.length < 10) {
    return {
      success: false,
      message: "Invalid withdrawal address.",
    };
  }
  if (!data.chain || data.chain.length < 5) {
    return {
      success: false,
      message: "Invalid withdrawal chain.",
    };
  }
  return { success: true };
};

export const cancelOrder = (data) => {
  if (!data.orderId || data.orderId.length < 22) {
    return {
      success: false,
      message: "Invalid orderId.",
    };
  }
  return { success: true };
};

export const usdtDeposit = (data) => {
  if (
    !data.amount ||
    Number.isNaN(Number(data.amount)) ||
    Number(data.amount) < 500 ||
    Number(data.amount) > 50000
  ) {
    return {
      success: false,
      message: "Invalid INR amount.",
    };
  }
  return { success: true };
};
