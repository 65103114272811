import nomoexLoader from "../../assets/nomoexLoader.gif";
import styles from "../../styles/common/Common.module.scss";

const Spinner = () => {
  return (
    <div className={styles.spinnerContainer}>
      <img src={nomoexLoader} alt="spinner-gif" className={styles.spinner} />
    </div>
  );
};

export default Spinner;
